import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import CryptoJS from 'crypto-js';

import globals from 'globalConstant';

import SMSContext from 'shared/globalStates/contextSMS';

import FetchLib from 'shared/helpers/FetchLib';

import LoginForm from 'components/organisms/LoginForm';

const URL_API_LOGIN = `${globals.uriAPI}/auth/provider/login`;

interface Data {
  user: string;
  password: string;
}

const LoginProvider = () => {
  const navigate = useNavigate();

  const { setSMSCode } = SMSContext.useSMSContext();

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (info: Data) => {
      const phoneNumber = await prompt(
        'Please enter phone number',
        '+121234568'
      );

      const res = await FetchLib.fetchPost(
        URL_API_LOGIN,
        {
          ...info,
          phoneNumber: phoneNumber,
        },
        null
      );

      if (res?.response === 'success' && res?.code) {
        const decryptedString = await CryptoJS.AES.decrypt(
          res.code,
          String(globals.REACT_APP_SECRET_SMS_PRHASE)
        );
        const decryptedCode = await decryptedString.toString(CryptoJS.enc.Utf8);
        setTimeout(async () => {
          await setSMSCode(decryptedCode ?? null);
          navigate('verification', { state: { code: decryptedCode } });
        }, 1200);
        return;
      }

      return;
    },
    [navigate, setSMSCode]
  );

  return (
    <LoginForm
      onSubmit={handleSubmit}
      toForgotPass="/forgot-pass/provider"
      toForgotUser="/forgot-user/provider"
    />
  );
};

export default LoginProvider;
