import { store } from 'shared/globalStates/ReduxSession/store';
import { Janus } from './Janus/Janus_GateWay';
import { shareScreen } from './Janus/LiveSession/VideoRoom/shareScreeen/shareScreen';
import { activePIP, hiddenPIP } from './PIP';
import { randomString } from './utils';

const downloadStream = (chunks: BlobPart[], name: string) => {
  console.log('chuks =>', chunks);
  const blob = new Blob(chunks, {
    type: 'video/webm',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = `${name}.webm`;
  a.click();
  window.URL.revokeObjectURL(url);
};

const handleDataAvailable = (e: BlobEvent, name: string) => {
  console.log('data-available', e);
  if (e.data.size > 0) {
    const copyChunks: any[] = [];
    copyChunks.push(e.data);

    console.log(copyChunks);

    downloadStream(copyChunks, name);
  }
};

export const SessionActions = {
  // Share screen
  shareScreen: () => {
    console.log('screen');
    shareScreen();
  },

  // Record Screen
  recordScreen: (
    canvas: HTMLCanvasElement,
    recorderName: string
  ): MediaRecorder => {
    const stream = canvas.captureStream(30); // Frames for second

    const recorder = new MediaStream([
      ...stream.getVideoTracks(),
      ...Janus.savedTracks.getAudioTracks(),
    ]);

    const options = { mimeType: 'video/webm; codecs=vp9' };
    const mediaRecorder = new window.MediaRecorder(recorder, options);

    mediaRecorder.ondataavailable = (e) => handleDataAvailable(e, recorderName);

    return mediaRecorder;
  },

  // Screenshot
  screenshot: (canvas: HTMLCanvasElement): string => {
    const link = document.createElement('a');

    const capture = canvas.toDataURL();

    // Only download file (test enviroment)
    link.href = capture;
    link.download = 'test file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return capture;
  },

  // Fullscreen
  openFullScreen: () => {
    const container = document.getElementById(
      'session_container'
    ) as HTMLDivElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (container) {
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        /* Safari */
        container.webkitRequestFullscreen();
      } else if (container.msRequestFullscreen) {
        /* IE11 */
        container.msRequestFullscreen();
      } else if (container.mozRequestFullScreen) {
        /* Mozilla */
        container.mozRequestFullScreen();
      }
    }
  },

  closeFullScreen: () => {
    const container = document.getElementById(
      'session_container'
    ) as HTMLDivElement & {
      exitFullscreen(): Promise<void>;
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };

    if (container) {
      if (container.exitFullscreen) {
        container.exitFullscreen();
      } else if (container.webkitExitFullscreen) {
        /* Safari */
        container.webkitExitFullscreen();
      } else if (container.msExitFullscreen) {
        /* IE11 */
        container.msExitFullscreen();
      } else if (container.mozCancelFullScreen) {
        /* Mozilla */
        container.mozCancelFullScreen();
      }
    }
  },

  // Change devices
  // changeDevices: (sfuHandler: any) => {
  //   createNewUserOffer(sfuHandler);
  // },

  // Exit session
  exitSession: (sfuHandler: any) => {
    if (sfuHandler) {
      const leave = {
        request: 'leave',
      };

      sfuHandler.send({ message: leave });

      window.location.assign('/leave-session');
    }
  },

  // End session
  endSession: (sfuHandler: any) => {
    if (sfuHandler) {
      const { room } = store.getState().LiveSession;

      const end = {
        request: 'destroy',
        room,
      };

      sfuHandler.send({ message: end });
    }
  },

  // Send message
  sendMessage: (sfuChatHandler: any, message: string) => {
    const { room } = store.getState().LiveSession;

    const request = {
      textroom: 'message',
      transaction: randomString(12),
      room,
      text: message,
      ack: false,
    };

    sfuChatHandler.data({
      text: JSON.stringify(request),
      error: () => {},
      success: () => {},
    });
  },

  // init PIP
  initPIP: async (
    elementId: string,
    callbackCamera: () => void,
    callbackMicrophone: () => void
  ) => {
    const element = document.getElementById(elementId) as HTMLVideoElement;

    await activePIP(element, callbackCamera, callbackMicrophone);
  },
  // end PIP
  endPIP: async () => {
    await hiddenPIP();
  },
};
