export function randomString(len: any, charSet = '') {
  charSet =
    charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomStringValue = '';
  for (let i = 0; i < len; i++) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    randomStringValue += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomStringValue;
}
