import { Janus } from 'shared/helpers/Janus/Janus_GateWay';
import { LiveSessionTypes } from '../types/livessesion_types';

// Interfaces
export interface ISession {
  devices: {
    camera: null | MediaDeviceInfo;
    microphone: null | MediaDeviceInfo;
    name: string;
  };
  janus: {
    publisherId: number | null;
    publisherPrivateId: number | null;
    remotes: any[];
    janusInstance: null | Janus;
    janusScreenInstance: null | Janus;
    messages: any[];
    errorInCaptureImage: boolean;
    local_stream: any;
  };
  room: number;
  sidebarSelected: string;
}

interface IAction {
  type: LiveSessionTypes | '';
  value: any;
}

// Initial values
const initialState: ISession = {
  devices: {
    camera: null,
    microphone: null,
    name: '',
  },
  janus: {
    publisherId: null,
    publisherPrivateId: null,
    remotes: [],
    janusInstance: null,
    janusScreenInstance: null,
    messages: [],
    errorInCaptureImage: false,
    local_stream: null,
  },
  sidebarSelected: '',
  room: 0,
};

// Desectructure Live Session Types
const {
  LIVESESSION_SET_DEVICES,
  LIVESESSION_SET_JANUS_INSTANCE,
  LIVESESSION_SET_REMOTES,
  LIVESESSION_SET_ROOM,
  LIVVESESSION_SET_PUBLISHER_ID,
  LIVVESESSION_SET_PUBLISHER_PRIVATE_ID,
  LIVESESSION_UNSET_REMOTE,
  LIVESESSION_SET_JANUS_ROOM_INSTANCE,
  LIVESESSION_SET_ERROR_IN_CAPTURE_IMAGE,
  LIVESESSION_SET_CHAT_MESSAGE,
  LIVESESSION_SET_LOCAL_STREAM,
} = LiveSessionTypes;

// Reducer
const reducer = (
  state: ISession = initialState,
  action: IAction = { type: '', value: '' }
): ISession => {
  switch (action.type) {
    case LIVESESSION_SET_DEVICES:
      return {
        ...state,
        devices: action.value,
      };

    case LIVESESSION_SET_JANUS_INSTANCE:
      return {
        ...state,
        janus: {
          ...state.janus,
          janusInstance: action.value,
        },
      };

    case LIVESESSION_SET_JANUS_ROOM_INSTANCE:
      return {
        ...state,
        janus: {
          ...state.janus,
          janusScreenInstance: action.value,
        },
      };

    case LIVESESSION_SET_ERROR_IN_CAPTURE_IMAGE:
      return {
        ...state,
        janus: {
          ...state.janus,
          errorInCaptureImage: action.value,
        },
      };

    case LIVESESSION_SET_LOCAL_STREAM:
      return {
        ...state,
        janus: {
          ...state.janus,
          local_stream: action.value,
        },
      };

    case LIVESESSION_UNSET_REMOTE:
      return {
        ...state,
        janus: {
          ...state.janus,
          remotes: state.janus.remotes.filter(
            (remote) => remote.id !== action.value
          ),
        },
      };
    case LIVESESSION_SET_ROOM:
      return {
        ...state,
        room: action.value,
      };

    case LIVVESESSION_SET_PUBLISHER_ID:
      return {
        ...state,
        janus: {
          ...state.janus,
          publisherId: action.value,
        },
      };

    case LIVVESESSION_SET_PUBLISHER_PRIVATE_ID:
      return {
        ...state,
        janus: {
          ...state.janus,
          publisherPrivateId: action.value,
        },
      };

    case LIVESESSION_SET_REMOTES:
      const hash: any = {};
      const remotes = [...state.janus.remotes, ...action.value].filter((o) =>
        hash[o.id] ? false : (hash[o.id] = true)
      );

      return {
        ...state,
        janus: {
          ...state.janus,
          remotes,
        },
      };

    case LIVESESSION_SET_CHAT_MESSAGE:
      const messages = [...state.janus.messages, action.value];

      return {
        ...state,
        janus: {
          ...state.janus,
          messages,
        },
      };
    default:
      return state;
  }
};

export default reducer;
