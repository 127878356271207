import styled from 'styled-components';
import fontsFamily from 'theme/fonts';

export const Split = styled.div`
  border: 1px solid #d8d8d8;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1 / 3;
  row-gap: 3px;
`;

export const ExpandTwoColumns = styled.div`
  grid-column: 1 / 3;
`;

export const ExpandThreeColumns = styled.div`
  grid-column: 1 / 4;
`;

export const SubElements = styled.div`
  padding-left: 1.1rem;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 3px;
`;

export const Input = styled.input`
  font-family: ${fontsFamily.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5b5b5b;
  padding: 3.5px 1.5px;
`;

export const TypeBelling = styled.div`
  &.no_selected {
    color: #535353 !important;
  }
`;
