import styled, { keyframes } from 'styled-components';
import theme from 'theme';
import colorsPalette from 'theme/colors';

// ANimation

const open = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: calc(36px * 4);
    }
`;

const close = keyframes`
    0% {
        height: calc(36px * 4);
    }
    100% {
        height: 0;
    }
`;

export const SelectContainer = styled.div`
  align-items: center;
  color: #808080;
  display: grid;
  font-family: ${theme.fontsFamily.inter};
  grid-template-columns: max-content 1fr max-content;
  height: 44px;
  justify-content: center;
  padding: 16px 13px;
  place-content: center;
  text-align: center;
  width: 168px;
  cursor: pointer;

  & > * {
    cursor: pointer;
  }

  & > .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    cursor: pointer;
    & > * {
      cursor: pointer;
    }

    & > .red {
      color: #c30707;
    }
    & > .yellow {
      color: #ffcc00;
    }
    & > .green {
      color: #34c759;
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  border-radius: 4px;
  border: 1px solid #bbbfc1;
  background: ${colorsPalette.white};
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 0px;
  & > li {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #ededed;
    column-gap: 10px;
    cursor: pointer;

    & > * {
      cursor: pointer;
    }

    &.red {
      color: #c30707;
    }
    &.yellow {
      color: #ffcc00;
    }
    &.green {
      color: #34c759;
    }
  }

  &.open {
    animation: ${open} 1s forwards;
  }

  &.close_true {
    animation: ${close} 1s forwards;
  }
`;
