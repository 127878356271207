import colors from './colors';
import fontsFamily from './fonts';
import breakpoints from './breakpoints';

const mediaQueries = {
  // auxiliar media queries
  xSmallAndHeightScreen: `@media screen and (max-width: ${breakpoints.sm}px) and (min-height: 500px) and (max-height: 600px)`,
  xxSmallScreen: `@media screen and (max-width: ${breakpoints.xxsm}px)`,

  // Reference type media queries to First Mobile
  xSmallScreen: `@media screen and (min-width: ${breakpoints.xsm}px)`,
  smallScreen: `@media screen and (min-width: ${breakpoints.sm}px)`,
  mediumScreen: `@media screen and (min-width: ${breakpoints.md}px)`,
  largeScreen: `@media screen and (min-width: ${breakpoints.lg}px)`,
  xLargeScreen: `@media screen and (min-width: ${breakpoints.xlg}px)`,
};

const theme = {
  colors,
  fontsFamily,
  breakpoints,
  mediaQueries,
};

export default theme;
