// PATIENT = 1, HOST = 2, SUPERHOST = 3, CONCERGE = 4, ADMIN = 5

import React from 'react';
import { Roles } from 'globalConstant';
import { SidebarIcons } from './icons';

interface IItems {
  name: string;
  icon: JSX.Element;
  path: string;
  privileges: Roles[];
  subItems?: IItems[];
  markWithChildrenRoutes?: boolean;
}

export const SidebarLeftItems: IItems[] = [
  {
    icon: <SidebarIcons.WorkFlow />,
    name: 'Workflow',
    path: 'workflow/*',
    privileges: [Roles.HOST],
    markWithChildrenRoutes: true,
  },
  {
    icon: <SidebarIcons.Comunication />,
    name: 'Comunications',
    path: 'comunications/*',
    privileges: [Roles.HOST],
    subItems: [
      {
        icon: <SidebarIcons.MessagePMFSH />,
        name: 'Messages',
        path: 'comunications/chat',
        privileges: [Roles.HOST],
      },
      {
        icon: <SidebarIcons.DocumentsImages />,
        name: 'Documents',
        path: 'comunications/documents-and-images-received',
        privileges: [Roles.HOST],
      },
      {
        icon: <SidebarIcons.ReferalRequest />,
        name: 'Referal Request Received',
        path: 'comunications/referal-request-comunication',
        privileges: [Roles.HOST],
      },
      {
        icon: <SidebarIcons.FaxesReceived />,
        name: 'Faxes Received',
        path: 'comunications/faxes-received',
        privileges: [Roles.HOST],
      },
    ],
  },
  {
    icon: <SidebarIcons.Reports />,
    name: 'Reports',
    path: 'reports/*',
    privileges: [Roles.HOST],
    subItems: [
      {
        icon: <SidebarIcons.PatientsImagesForReview />,
        name: 'Patient Images for Review',
        path: 'reports/patients-images-for-review',
        privileges: [Roles.HOST],
      },
      {
        icon: <SidebarIcons.SecondOpinionConsultsSent />,
        name: 'Second Opinion Consults Sent',
        path: 'reports/second-opinion-consults-sent',
        privileges: [Roles.HOST],
      },
      {
        icon: <SidebarIcons.ReferalRequestSent />,
        name: 'Referal Request Sent',
        path: 'reports/referal-request-sent',
        privileges: [Roles.HOST],
      },
    ],
  },
  {
    icon: <SidebarIcons.AffiliatedOrganizations />,
    name: 'Affiliated Organizations',
    path: 'affiliated_organizations',
    privileges: [Roles.HOST],
    subItems: [],
  },
  {
    icon: <SidebarIcons.Billing />,
    name: 'Billing',
    path: 'billing/*',
    privileges: [Roles.HOST],
    subItems: [
      {
        icon: <SidebarIcons.Authorizations />,
        name: 'Authorizations',
        path: 'billing/authorizations',
        privileges: [Roles.HOST],
      },
    ],
  },
];
