import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import fontsFamily from './fonts';

const GlobalStyle = createGlobalStyle`
	${styledNormalize}
	body {
		font-family: ${fontsFamily.helvetica} !important;
		scroll-behavior: smooth;
	}

	::-ms-reveal 
	{
		display: none;
	}
	
	* {
		scroll-behavior: smooth;
	}

	label {
		font-size: 1rem;
		line-height: 20px;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: ${fontsFamily.helvetica};
	}

`;

export default GlobalStyle;
