import styled from 'styled-components';

import theme from 'theme';
import { TypesPallete } from 'theme/colors';

import Text from 'components/atoms/Text';

const { colors } = theme;

export const TextStyle = styled(Text)``;

interface IItemProps {
  $palette?: TypesPallete;
  $isExpand?: boolean;
}

export const ItemStyle = styled.button<IItemProps>`
  cursor: pointer;

  p {
    padding: 10px 0;
    color: ${colors.white};
  }

  label {
    display: flex;
    flex-direction: row;
    justify-content: ${({ $isExpand }) =>
      $isExpand ? 'flex-start' : 'center'};
    align-items: center;
    cursor: pointer;

    svg {
      width: ${({ $isExpand }) => ($isExpand ? '25px' : '20px')};
      height: ${({ $isExpand }) => ($isExpand ? '25px' : '20px')};
      color: ${colors.white};
      fill: ${colors.white};
    }
  }

  &.selected__main {
    p {
      ${({ $palette }) =>
        $palette && `color: ${colors.appTheme[$palette].primary}`};
    }

    label {
      svg {
        ${({ $palette }) =>
          $palette && `color: ${colors.appTheme[$palette].primary}`};
        ${({ $palette }) =>
          $palette && `fill: ${colors.appTheme[$palette].primary}`};
      }
    }
  }

  &:hover {
  }
`;

export const AncleStyle = styled.label``;
