import { setMessage } from 'shared/globalStates/ReduxSession/actions/livesession_action';
import { store } from 'shared/globalStates/ReduxSession/store';

export const EventChats = (
  chatHandler: any,
  eventType: any,
  data: any,
  setChatHandler: any
) => {
  switch (eventType) {
    case 'joined':
      setChatHandler(chatHandler);
      break;
    case 'ondata':
      const message = {
        displayName: data.user.slice(37),
        date: data.date,
        text: data.data,
      };
      store.dispatch(setMessage(message));
      break;
    default:
      break;
  }
};
