import React from 'react';

import Footer from 'components/organisms/Footer';
import Navbar from 'components/organisms/NavbarLogin';
import RightSideOfForgot from 'components/organisms/RightSideOfForgot';
import LeftSideOfForgot from 'components/organisms/LeftSideOfForgot';

import { Container, ForgotContainer, PrincipalContent } from './style';

function ForgotUser() {
  return (
    <Container>
      <Navbar />
      <ForgotContainer>
        <PrincipalContent>
          <LeftSideOfForgot />
          <RightSideOfForgot />
        </PrincipalContent>
      </ForgotContainer>
      <Footer />
    </Container>
  );
}

export default ForgotUser;
