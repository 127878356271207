import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import styled, { keyframes } from 'styled-components';
import colorsPalette from 'theme/colors';
import fontsFamily from 'theme/fonts';
import { IIcons } from './Icons';

const IconColors = {
  Visit: '#003D98',
  StartLiveSession: '#43AB59',
  ScheduleSession: '#FFBF78',
  ImageReviewReports: '#148EFE',
  SecondOpinionReports: '#B33EEB',
  ReferralRequest: '#23DDCE',
  ChronicCareManagement: '#EBA800',
  TherapeuticPatientMonitoring: '#148EFE',
  RemotePatientMonitoring: '#B33EEB',
  Billing: '#43AB59',
  Prescribe: '#706EFA',
};

export type TDropShadows =
  | 'Visit'
  | 'ChronicCareManagement'
  | 'ImageReviewReports'
  | 'StartLiveSession'
  | 'ReferralRequest'
  | 'RemotePatientMonitoring'
  | 'ScheduleSession'
  | 'SecondOpinionReports'
  | 'TherapeuticPatientMonitoring'
  | 'Billing'
  | 'Prescribe';

const dropShadows = {
  StartLiveSession: 'drop-shadow(0px 4px 4px rgba(0, 288, 59, 0.34))',
  ScheduleSession: 'drop-shadow(0px 4px 4px rgba(255, 191, 120, 0.44))',
  ImageReviewReports: 'drop-shadow(0px 4px 4px rgba(20, 142, 254, 0.37))',
  SecondOpinionReports: 'drop-shadow(0px 4px 4px rgba(179, 62, 235, 0.33))',
  ReferralRequest: 'drop-shadow(0px 4px 4px rgba(35, 221, 206, 0.44))',
  Visit: 'drop-shadow(0px 4px 4px rgba(0, 61, 152, 0.44))',
  ChronicCareManagement: 'drop-shadow(0px 4px 4px rgba(235, 168, 0, 0.44))',
  TherapeuticPatientMonitoring:
    'drop-shadow(0px 4px 4px rgba(20, 142, 254, 0.44))',
  RemotePatientMonitoring: 'drop-shadow(0px 4px 4px rgba(179, 62, 235, 0.44))',
  Billing: 'drop-shadow(0px 4px 4px rgba(67, 171, 89, 0.44))',
  Prescribe: 'drop-shadow(0px 4px 4px rgba(112, 110, 250, 0.44))',
};

const fadeInText = keyframes`
  from {
    height: max-content;
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;

const fadeOutText = keyframes`
  from {
    opacity: 1;
  }

  95% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    height: 0px;
    overflow: hidden;
  }
`;

export const MainContainer = styled.nav`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
`;

export const SliderComponent = styled(Slider)`
  width: 100%;
  & > button {
    &.slick-arrow {
      color: gray;
      top: 25.5px;
    }
  }

  & > .slick-list {
    padding-top: 5px;
    width: 100%;
  }
`;

interface IItemContainer {
  shadow: keyof IIcons;
}

export const ItemContainer = styled.div<IItemContainer>`
  width: 134px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  & > svg {
    height: 36.5px;
    color: ${({ shadow }) => IconColors[shadow]};
  }

  &.disable > svg {
    color: ${colorsPalette.gray};
  }

  & > p {
    margin-top: 10px;
    font-family: ${fontsFamily.inter};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #868686;
    opacity: 0;
    text-align: center;
  }

  &:hover {
    & > svg {
      transform: scale(1.2);
      transition: 1s;
      filter: ${({ shadow }) => dropShadows[shadow]};
    }

    &.disable > svg {
      filter: none;
      transform: none;
    }

    &.disable > p {
      animation: none;
    }

    & > p {
      transition: 1s;
      animation: ${fadeInText} 1s forwards;
    }
  }

  &:not(:hover) {
    & > svg {
      transform: none;
      transition: 1s;
    }
    & > p {
      transition: 1s;
      animation: ${fadeOutText} 1s forwards;
      // display: none;
    }

    &.disable > svg {
      filter: none;
      transform: none;
    }

    &.disable > p {
      animation: none;
    }
  }
`;

interface IItemContainerNavLink {
  shadow: keyof IIcons;
}

export const ItemContainerNavLink = styled(NavLink)<IItemContainerNavLink>`
  width: 134px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  outline: none;

  & > svg {
    height: 36.5px;
    color: ${({ shadow }) => IconColors[shadow]};
  }

  &.disable > svg {
    color: ${colorsPalette.gray};
  }

  & > p {
    margin-top: 10px;
    font-family: ${fontsFamily.inter};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #868686;
    opacity: 0;
    text-align: center;
  }

  &:hover {
    & > svg {
      transform: scale(1.2);
      transition: 1s;
      filter: ${({ shadow }) => dropShadows[shadow]};
    }

    &.disable > svg {
      filter: none;
      transform: none;
    }

    &.disable > p {
      animation: none;
    }

    & > p {
      transition: 1s;
      animation: ${fadeInText} 1s forwards;
    }
  }

  &:not(:hover) {
    & > svg {
      transform: none;
      transition: 1s;
    }

    &.disable > svg {
      filter: none;
      transform: none;
    }

    &.disable > p {
      animation: none;
    }

    & > p {
      animation: ${fadeOutText} 1s forwards;
      transition: 1s;
      // display: none;
    }
  }
`;

export const SelectedItem = styled.div`
  width: 8px;
  height: 8px;
  background: #346aff;
  border-radius: 50%;
  margin-top: 5px;
`;
