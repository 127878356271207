import React from 'react';

import SMSContext from 'shared/globalStates/contextSMS';

import Footer from 'components/organisms/Footer';
import Navbar from 'components/organisms/NavbarLogin';
import RightSideOfLogin from 'components/organisms/RightSideOfLogin';
import LeftSideOfLogin from 'components/organisms/LeftSideOfLogin/LeftSideOfLogin';

import { Container, LoginContainer, PrincipalContent } from './style';

function Login() {
  return (
    <SMSContext.SmsProvider>
      <Container>
        <Navbar />
        <LoginContainer>
          <PrincipalContent>
            <LeftSideOfLogin />
            <RightSideOfLogin />
          </PrincipalContent>
        </LoginContainer>
        <Footer />
      </Container>
    </SMSContext.SmsProvider>
  );
}

export default Login;
