/* eslint-disable @typescript-eslint/dot-notation */
import { Janus } from '../../Janus_GateWay';

export const publishToRoom = (
  janusInstance: Janus | null,
  opaqueId: string,
  room: number,
  username: string,
  callback: (pluginHandle: any, eventType: any, data?: any) => void
) => {
  let sfutest: any = null,
    mystream: any = null;

  if (!janusInstance || janusInstance.attach === undefined) {
    console.log('JANUS INSTANCE IS NO DEFINED OR METOD ATTACH');

    return;
  }

  janusInstance.attach({
    plugin: 'janus.plugin.videoroom',
    opaqueId,
    success: (pluginHandle: any) => {
      sfutest = pluginHandle;
      console.log('---- This is a publisher/manager');

      const register = {
        request: 'join',
        room,
        ptype: 'publisher',
        display: username,
      };

      pluginHandle.send({ message: register });
    },
    error: function (error: any) {
      console.log('  -- Error attaching plugin...', error);
      callback(sfutest, 'error', error);
    },
    consentDialog: function (on: any) {
      console.debug('Consent dialog should be ' + (on ? 'on' : 'off') + ' now');
    },
    mediaState: function (medium: any, on: any) {
      console.log(
        'console ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium
      );
    },
    iceState: function (on: any) {
      let timeoutId = null;
      if (on === 'disconnected') {
        const reload = () => document.location.reload();
        timeoutId = setTimeout(reload, 1000 * 3);
      } else if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    },
    webrtcState: function (on: any) {
      console.log(
        'console says our WebRTC PeerConnection is ' +
          (on ? 'up' : 'down') +
          ' now'
      );

      const bitrateSelected = 0;
      const bitrate = bitrateSelected * 1000;
      if (bitrate === 0) {
        Janus.log('Not limiting bandwidth via REMB');
      } else {
        Janus.log('Capping bandwidth to ' + bitrate + ' via REMB');
      }
      sfutest.send({
        message: { request: 'configure', bitrate: bitrate },
      });
      return false;
    },
    onmessage: function (msg: any, jsep: any) {
      console.debug(' ::: Got a message (publisher) :::');
      console.debug(msg);

      console.log('Got message', msg);

      const event = msg.videoroom;
      if (event !== undefined && event !== null) {
        if (event === 'joined') {
          callback(sfutest, 'joined', msg);
        } else if (event === 'destroyed') {
          console.warn('The room has been destroyed!');
          callback(sfutest, 'destroyed', event);
        } else if (event === 'event') {
          if (msg.error !== undefined && msg.error !== null) {
            callback(sfutest, 'error', msg);
          } else if (msg.publishers !== undefined && msg.publishers !== null) {
            callback(sfutest, 'publishers', msg);
          } else if (msg['leaving'] !== undefined && msg['leaving'] !== null) {
            callback(sfutest, 'leaving', msg);
          } else if (
            msg['unpublished'] !== undefined &&
            msg['unpublished'] !== null
          ) {
            callback(sfutest, 'unpublished', msg);
          }
        }
      }

      if (jsep !== undefined && jsep !== null) {
        console.debug('Handling SDP as well...');
        console.debug(jsep);
        sfutest.handleRemoteJsep({ jsep: jsep });
        // Check if any of the media we wanted to publish has
        // been rejected (e.g., wrong or unsupported codec)
        const audio = msg['audio_codec'];
        if (
          mystream &&
          mystream.getAudioTracks() &&
          mystream.getAudioTracks().length > 0 &&
          !audio
        ) {
          // Audio has been rejected
          console.log(
            "Our audio stream has been rejected, viewers won't hear us"
          );
        }
        const video = msg['video_codec'];
        if (
          mystream &&
          mystream.getVideoTracks() &&
          mystream.getVideoTracks().length > 0 &&
          !video
        ) {
          console.log(
            "Our video stream has been rejected, viewers won't see us"
          );
        }
      }
    },
    onlocaltrack: function (track: any) {
      console.debug(' ::: Got a local stream :::');
      const stream = new window.MediaStream();
      stream.addTrack(track.clone());
      mystream = stream;
      callback(sfutest, 'onlocaltrack', track);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onremotetrack: function (stream: any) {
      // The publisher stream is sendonly, we don't expect anything here
    },
    oncleanup: function () {
      console.log(
        ' ::: Got a cleanup notification: we are unpublished now :::'
      );
      callback(sfutest, 'oncleanup');
    },
  });

  return;
};
