/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import colorsPalette, { appTheme, TypesPallete } from 'theme/colors';

export const Container = styled.section`
  display: grid;
  height: max-content;
  grid-template-areas:
    "head head head head"
    "nav main main aside";
  grid-template-columns: max-content 1fr 1fr minmax(0px, max-content);
  gap: 0 8px;
  padding: 0 10px;
  min-height: 100vh;
`;

interface IContentBox {
  pallete: TypesPallete;
}

export const ContentBox = styled.div<IContentBox>`
  background-color: ${({ pallete }) => appTheme[pallete].backdrop_container};
  backdrop-filter: blur(2px);
  border-radius: 31px 0px 0px 0px;
  padding-top: 11px;
  grid-area: main;
  position: relative;
  width: 100%;
`;

export const ContainerBox = styled.div`
  background: ${colorsPalette.white};
  border-radius: 31px 0px 0px 0px;
  height: 100%;
  width: 100%;
`;