import styled from 'styled-components';

import theme from 'theme';

const { mediaQueries, colors } = theme;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  ${mediaQueries.mediumScreen} {
    max-width: 417px;
    min-height: 621.5px;
    max-height: 621.5px;
    position: relative;
  }
`;

interface ContainerSectionProps {
  isDark: boolean;
}

export const ContainerSection = styled.section<ContainerSectionProps>`
  height: 100%;
  background: ${({ isDark }) => (isDark ? colors.darkBlue : colors.white)};
  color: ${({ isDark }) => (isDark ? colors.white : colors.darkBlue)};
  flex: 1;
  padding: 36px 27px;
`;
