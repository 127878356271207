import styled, { css } from 'styled-components';

import theme from 'theme';
import { TypesColors } from 'theme/colors';

const { fontsFamily, colors } = theme;

export type VariantText =
  | 'HELVETICA_16_500'
  | 'HELVETICA_39_700'
  | 'HELVETICA_23_700'
  | 'HELVETICA_16_400'
  | 'HELVETICA_16_700'
  | 'HELVETICA_17_700'
  | 'HELVETICA_18_700'
  | 'HELVETICA_20_700'
  | 'HELVETICA_12_400'
  | 'HELVETICA_14_400'
  | 'ARIAL_25_700'
  | 'LATO_14_400'
  | 'LATO_22_400'
  | 'POPPINS_13_400'
  | 'POPPINS_20_700'
  | 'POPPINS_20_400'
  | 'ROBOTO_12_400'
  | 'ROBOTO_12_700'
  | 'ROBOTO_17_400'
  | 'ROBOTO_24_400'
  | 'SORA_18_400'
  | 'SORA_18_600'
  | 'INTER_8_400'
  | 'INTER_10_500'
  | 'INTER_10_600'
  | 'INTER_12_400'
  | 'INTER_12_700'
  | 'INTER_14_600'
  | 'INTER_700_16'
  | 'INTER_500_16'
  | 'INTER_600_24'
  | 'INTER_48_700'
  | 'MULISH_12_400'
  | 'MANROPE_10_400';

export const cssTextVariant = {
  HELVETICA_16_500: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
  `,
  HELVETICA_16_700: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 16px;
    line-height: 13px;
  `,
  HELVETICA_17_700: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
  `,
  HELVETICA_18_700: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  `,
  HELVETICA_39_700: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 800;
    font-size: 16px;
    line-height: 14px;
  `,
  HELVETICA_23_700: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 800;
    font-size: 16px;
    line-height: 14px;
  `,
  HELVETICA_16_400: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 800;
    font-size: 16px;
    line-height: 30px;
  `,
  HELVETICA_20_700: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  HELVETICA_12_400: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  `,
  HELVETICA_14_400: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
  `,
  ARIAL_25_700: css`
    font-family: ${fontsFamily.arial};
    font-weight: 700;
    font-size: 25px;
    line-height: 48px;
  `,
  LATO_14_400: css`
    font-family: ${fontsFamily.lato};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  `,
  LATO_22_400: css`
    font-family: ${fontsFamily.lato};
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
  `,
  POPPINS_13_400: css`
    font-family: ${fontsFamily.poppins};
    font-weight: 400;
    font-size: 13px;
    line-height: 135.5%;
  `,
  POPPINS_20_700: css`
    font-family: ${fontsFamily.poppins};
    font-weight: 700;
    font-size: 20px;
    line-height: 135.5%;
  `,
  POPPINS_20_400: css`
    font-family: ${fontsFamily.poppins};
    font-weight: 400;
    font-size: 20px;
    line-height: 135.5%;
  `,
  ROBOTO_12_400: css`
    font-family: ${fontsFamily.roboto};
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  `,
  ROBOTO_12_700: css`
    font-family: ${fontsFamily.roboto};
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
  `,
  ROBOTO_17_400: css`
    font-family: ${fontsFamily.roboto};
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
  `,
  ROBOTO_24_400: css`
    font-family: ${fontsFamily.roboto};
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
  `,
  SORA_18_400: css`
    font-family: ${fontsFamily.sora};
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
  `,
  SORA_18_600: css`
    font-family: ${fontsFamily.sora};
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
  `,
  INTER_8_400: css`
    font-family: ${fontsFamily.inter};
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
  `,
  INTER_10_500: css`
    font-family: ${fontsFamily.inter};
    font-weight: 500;
    font-size: 10.5588px;
    line-height: 13px;
  `,
  INTER_10_600: css`
    font-family: ${fontsFamily.inter};
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
  `,
  INTER_12_400: css`
    font-family: ${fontsFamily.inter};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  `,
  INTER_12_700: css`
    font-family: ${fontsFamily.inter};
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
  `,
  INTER_14_600: css`
    font-family: ${fontsFamily.inter};
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  `,
  INTER_700_16: css`
    font-family: ${fontsFamily.inter};
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  INTER_500_16: css`
    font-family: ${fontsFamily.inter};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  `,
  INTER_600_24: css`
    font-family: ${fontsFamily.inter};
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
  `,
  INTER_48_700: css`
    font-family: ${fontsFamily.inter};
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  `,
  MULISH_12_400: css`
    font-family: ${fontsFamily.mulish};
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  `,
  MANROPE_10_400: css`
    font-family: ${fontsFamily.manrope};
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  `,
};

interface ITextProps {
  $variant: VariantText;
  $color?: TypesColors;
}

export const TextStyle = styled.p<ITextProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;
