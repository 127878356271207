import styled from 'styled-components';

import theme from 'theme';

const { colors, mediaQueries } = theme;

export const FooterStyled = styled.footer`
  &.footer {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

export const TermsAndConditionsContainer = styled.div`
  width: 100%;
  background: ${colors.darkBlue};
  display: flex;
  justify-content: center;
  ${mediaQueries.mediumScreen} {
    justify-content: start;
  }
`;

export const TermsAndConditions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 0.938rem 0;
  width: 100%;
  max-width: 24.375rem;
  & > * {
    width: 100%;
    text-align: center;
    border-left: 0.063rem solid ${colors.white};
  }
  & > *:first-child {
    border-left: none;
  }
`;
