import styled from 'styled-components';
import fontsFamily from 'theme/fonts';

export const Select = styled.select`
  width: 100%;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: 2px;
  font-family: ${fontsFamily.inter};
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
`;

export const Option = styled.option``;
