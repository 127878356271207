import { TextInput } from 'components/atoms/InputWithSadow/style';
import styled from 'styled-components';
import colorsPalette from 'theme/colors';
import fontsFamily from 'theme/fonts';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 10px 60px;
`;

export const Main = styled.div`
  width: 100%;
  max-width: 630px;
  position: relative;
`;

export const ProviderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.2rem;
  margin-top: 31px;
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  gap: 2rem;
  align-items: center;
`;

export const SwitcherContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: auto;
  gap: 0.2rem;
  align-items: center;
`;

export const SendEmailContainer = styled.div`
  display: grid;
  grid-template-columns: 390px 20px;
  grid-auto-rows: max-content;
  gap: 23.07px;
  width: 100%;
  align-items: center;
  margin-top: 28px;
`;

export const SubElements = styled.div`
  padding-left: 1.1rem;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 21.85px;
  margin-top: 13.93px;
`;

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1.18rem 1fr;
  gap: 1rem;
  width: 100%;
`;

export const Checkbox = styled.input`
  // Remove Apparence
  -webkit-appearance: none;
  appearance: none;

  // Add design
  background: ${colorsPalette.white};
  border: 2px solid #4071d7;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  place-content: center;

  &:checked {
    background: #4071d7;
  }

  &:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 4px;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 82.93px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
`;

interface IInputWithDollarContainer {
  withContent: boolean;
}

export const InputWithDollarContainer = styled.label<IInputWithDollarContainer>`
  background: ${colorsPalette.white};
  border-radius: 7.03922px;
  padding: 0px 18px;
  resize: none;
  outline: none;
  font-family: ${fontsFamily.mulish};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  display: flex;
  align-items: center;
  width: 125px;
  height: 36px;
  color: ${({ withContent }) => (withContent ? 'black' : '#acb2bc')};
  cursor: text;
  gap: 0.2rem;

  &.disabled {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #ffffff;
    cursor: not-allowed;
    & > * {
      cursor: not-allowed;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0.05)
        ),
        #ffffff;
    }
  }
`;

export const InputWithDollar = styled(TextInput)`
  padding: 0;
  filter: none;
  width: 100%;
  margin: 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #587dc9;
  background: #587dc9;
  border: 1px solid rgba(168, 168, 168, 0.2);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  width: 28px;
  height: 26px;
  cursor: pointer;
`;
