export const fallbackFonts = 'sans-serif';

export const fontsFamily = {
  helvetica: `'Helvetica'`,
  helveticaBold: `'Helvetica-bold'`,
  arial: `'Arial'`,
  lato: `'lato'`,
  poppins: `'Poppins'`,
  inter: `'Inter'`,
  roboto: `'Roboto'`,
  sora: `'Sora'`,
  manrope: `'Manrope'`,
  mulish: `'Mulish'`,
};

export type TypesFonts =
  | 'helvetica'
  | 'helveticaBold'
  | 'arial'
  | 'lato'
  | 'poppins'
  | 'inter'
  | 'sora'
  | 'roboto'
  | 'manrope'
  | 'mulish';

export default fontsFamily;
