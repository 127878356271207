import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SectionContainer = styled.section`
  background: #f8f8f8;
  border-radius: 16px;
  width: 100%;
  padding: 16px 18px;
  color: #535353;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const EditIcon = styled.button`
  width: 19px;
  height: 20px;
`;

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  margin-top: 47px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
`;
