import styled from 'styled-components';

export const Container = styled.div`
  padding: 18px 24px;
  position: relative;
  width: 100%;
  height: 100%;
`;

interface IBody {
  insertBorder?: boolean;
}

export const Body = styled.div<IBody>`
  padding: 10px 18px;
  padding-top: 18px;
  ${({ insertBorder }) =>
    insertBorder &&
    `
    border-top: 1px solid #dadada;
    margin-top: 6px;
    `}
`;

export const ContentHeader = styled.div`
  display: grid;
  grid-template-columns: 50px minmax(0, 1fr) max-content;
  position: relative;
  gap: 4rem;
  height: 50px;
`;

export const AddSessionElementIcon = styled.img`
  cursor: pointer;
  height: 40.62px;
`;
