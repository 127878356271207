import styled from 'styled-components';
import theme from 'theme';

const { fontsFamily, colors } = theme;

export const Table = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  flex-basis: 0;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const THead = styled.div`
  font-family: ${fontsFamily.manrope};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #64748b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0 0;
  display: grid;
  grid-template-columns: 1fr repeat(4, minmax(103.73px, max-content)) 125.87px;
  background: ${colors.white};
`;

export const CeldHead = styled.div`
  padding: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-left: 1px solid #d9d9d9;

  &:first-child {
    text-align: left;
    justify-content: start;
    padding-left: 34px;
    border-left: none;
  }
`;

export const TBody = styled.div`
  font-family: ${fontsFamily.manrope};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #001c45;
  margin-top: 5px;
`;

interface ITRowBody {
  PN: number | undefined;
  MN: number | undefined;
  MRN: number | undefined;
  DOB: number | undefined;
  Status: number | undefined;
  Action: number | undefined;
}

export const TRowBody = styled.div<ITRowBody>`
  display: grid;
  grid-template-columns: ${({ PN, MN, Action, DOB, MRN, Status }) =>
    `${PN}px ${MN}px ${MRN}px ${DOB}px ${Status}px ${Action}px`};
  background: ${colors.white};
  border-radius: 8px;
  margin-top: 5px;
`;

export const CeldBody = styled.div`
  padding: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:first-child {
    text-align: left;
    justify-content: start;
    padding-left: 34px;
  }
`;

export const StatusContainer = styled.div`
  border-radius: 20px;
  padding: 8px 16px;
  font-family: ${fontsFamily.inter};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;

  &.Active {
    background: #dcfbea;
    border: 1px solid #dcfbea;
    color: #249f5d;
  }

  &.Pending {
    background: #fff4cb;
    border: 1px solid #fff4cb;
    color: #ffc700;
  }

  &.Inactive {
    background: #ffe0e0;
    border: 1px solid #ffe0e0;
    color: #c30707;
  }
`;

// Icons

export const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: 22.74px 2px 22.74px;
  place-content: center;
  align-items: center;
  gap: 0.8rem;

  & > img {
    cursor: pointer;
  }
`;

export const SepareIcon = styled.div`
  width: 100%;
  height: 100%;
  background: #e0e2e7;
  border-radius: 20px;
`;

// Paginator

export const PaginatorContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-family: ${fontsFamily.inter};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 30px;
`;

export const PaginatorCelds = styled.button`
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    color: #969696;
    cursor: not-allowed;
  }

  &.selected {
    background: #0085ff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 1px rgba(0, 0, 0, 0.08);
    border: none;
    color: ${colors.white};
  }

  &:first-child,
  :last-child {
    width: 40px;
    height: 40px;
  }
`;
