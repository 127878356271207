import styled from 'styled-components';

import theme from 'theme';
import { TypesPallete } from 'theme/colors';

import TitleComponent from 'components/atoms/Title/Title';

const { colors, mediaQueries } = theme;

export const MainContainer = styled.aside`
  grid-area: aside;
  width: max-content;

  z-index: 1;
`;

export const SidebarStyled = styled.div`
  &.navigation__bar {
    width: 100%;
    // height: calc(100vh - 88px);

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
`;

export const ExpandStyleContainer = styled.div`
  position: relative;
  width: 100%;
`;

interface IToggle {
  $palette?: TypesPallete;
}

export const ToggleBarStyled = styled.button<IToggle>`
  &.toggle__expand {
    position: absolute;
    top: 10px;
    width: 25px;
    height: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    font-size: 12px;
    z-index: 1;

    &.expand {
      right: 65%;

      .icon__calendar {
        position: relative;

        svg {
          bottom: -15px;
          position: absolute;
          left: 15px;
        }
      }
    }

    &.contract {
      left: 0px;
      right: none;
    }

    &:hover {
      border-radius: 75px;
      background-color: ${({ $palette }) =>
        $palette ? colors.appTheme[$palette].primary : 'transparent'};
    }
  }
`;

export const TitleDoctorName = styled(TitleComponent)`
  margin-bottom: 10px;
  font-size: 14px;
`;

// Calendar

interface CalendarSectionProps {
  backgroudColor?: string;
  padding?: string;
  borderRadius?: string;
}

interface MonthAndYearSectionProps {
  color?: string;
  fontWeightMonthAndYear?:
    | 'bold'
    | 'normal'
    | 'bolder'
    | 'lighter'
    | 'initial'
    | 'inherit';
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const CalendarSection = styled.div<CalendarSectionProps>`
  background: ${(props) => props.backgroudColor || colors.whiteOpacity(0.15)};
  padding: ${(props) => props.padding || '10px'};
  border-radius: ${(props) => props.borderRadius || '8px'};
  width: 100%;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MonthAndYearSection = styled.div<MonthAndYearSectionProps>`
  display: flex;
  align-items: center;

  h4 {
    font-size: 15px;
    margin-bottom: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.color || colors.black};
    font-weight: ${(props) => props.fontWeightMonthAndYear || 'bold'};
  }
`;

export const ButtonsSection = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DayWeeksSection = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const WeeksSection = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

interface DayWeekProps {
  color?: string;
}

export const DayWeek = styled.div<DayWeekProps>`
  height: 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  padding-top: 6px;
  padding-right: 0px;
  color: ${(props) => props.color || colors.white};
`;

interface MonthProps {
  BgColor?: string;
}

export const Month = styled.div<MonthProps>`
  margin-top: 10px;
  border-radius: 4px;
  background-color: ${(props) => props.BgColor || colors.whiteOpacity(0.15)};
`;

interface DayProps {
  color?: string;
  BgColor?: string;
  borderColor?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  isBg?: boolean;
  themeSelected?: TypesPallete;
}

export const Day = styled.div<DayProps>`
  height: ${(props) => props.height || '30px'};
  width: ${(props) => props.width || '30px'};
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: center;
  padding: 10px 1.5px;
  ${(props) =>
    props.isBg &&
    props.themeSelected &&
    `background-color: ${colors.appTheme[props.themeSelected].primary}`};
  ${(props) => props.isBg && props.themeSelected && `border-radius: 75px`};
  color: ${(props) => props.color || colors.white};
  cursor: pointer;

  ${mediaQueries.xLargeScreen} {
    font-size: 12px;
  }
`;

interface TimelineProps {
  $qtyEvents?: number;
  $themeSelected?: TypesPallete;
}

export const ContainerTimeline = styled.div<TimelineProps>`
  display: flex;
  margin-top: 2rem;

  .fc-header-toolbar {
    height: 0.25rem;
    visibility: hidden;

    &.fc-header-toolbar {
      margin-bottom: 0;
    }
  }

  .fc-scroller {
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  .fc-scroller-liquid-absolute {
    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  .fc-theme-standard {
    th {
      border: none;
    }
  }

  .fc-col-header {
    display: none;
  }

  .fc-scrollgrid-section {
    &.fc-scrollgrid-section-body {
      &:first-child {
        display: none;
      }
    }

    &:nth-child(2) {
      display: none;
    }
  }

  .fc-view-harness-active {
    height: 50vh !important;
  }

  .fc-theme-standard {
    .fc-scrollgrid {
      border: none !important;
    }
  }

  .fc-theme-standard {
    td {
      border: none !important;
    }
  }

  .fc {
    width: 100%;

    .fc-timegrid-body {
      width: 100% !important;
    }

    .fc-scrollgrid-section-body {
      table {
        width: 100% !important;

        tbody {
          tr {
            height: 50px;
            border-bottom: solid 0.3px ${colors.white};
          }
        }
      }
    }

    .fc-timegrid-col {
      &.fc-day-today {
        background-color: transparent;
      }
    }

    .fc-timegrid-slot {
    }
  }

  .fc-timegrid-event-harness {
    .fc-timegrid-event {
      font-size: 10px;
      border-radius: 13px;
      color: ${({ $themeSelected }) =>
        $themeSelected && `${colors.appTheme[$themeSelected].blueCallsTxt}`};
    }

    .fc-v-event {
      width: ${({ $qtyEvents }) => !$qtyEvents && `80%`};
      border: none;
      background-color: ${({ $themeSelected }) =>
        $themeSelected && `${colors.appTheme[$themeSelected].callContainer}`};

      .fc-event-main {
        padding: 5px;
        display: flex;
        flex-direction: column;
        color: ${({ $themeSelected }) =>
          $themeSelected && `${colors.appTheme[$themeSelected].blueCallsTxt}`};
      }
    }
  }
`;

interface IEventsStyle {
  $qtyEvents?: number;
}

export const EventsStyle = styled.div<IEventsStyle>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  .call--icon,
  .events--content,
  .options--icon {
    width: 33.33%;
    display: flex;
  }

  .call--icon {
    justify-content: flex-start;
    align-items: center;
  }

  .options--icon {
    justify-content: flex-end;
    align-items: center;
  }

  .events--content {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

interface IPlusScheduleStyle {
  $topPx?: number;
  $themeSelected?: TypesPallete;
}

export const AddScheduleSessionStyled = styled.button<IPlusScheduleStyle>`
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 75px;
  background: ${({ $themeSelected }) =>
    $themeSelected && `${colors.appTheme[$themeSelected].primary}`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  right: 15px;
  bottom: calc(20% - ${({ $topPx }) => ($topPx ? $topPx : 0)}px);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

  svg {
  }

  ${mediaQueries.xLargeScreen} {
    bottom: calc(10% - ${({ $topPx }) => ($topPx ? $topPx : 0)}px);
  }
`;

export const FloatingButton = styled.button`
  position: absolute;
  background: #5487df;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  border-radius: 8px 0px 0px 8px;
  height: 36px;
  cursor: pointer;
  top: 0;
  right: -10px;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 12px 6px;
`;
