export enum LiveSessionTypes {
  // Devices
  LIVESESSION_SET_DEVICES = 'LIVESESSION_SET_DEVICES',

  //   Janus
  LIVESESSION_SET_JANUS_INSTANCE = 'LIVESESSION_SET_JANUS_INSTANCE',
  LIVESESSION_SET_JANUS_ROOM_INSTANCE = 'LIVESESSION_SET_JANUS_ROOM_INSTANCE',
  LIVVESESSION_SET_PUBLISHER_ID = 'LIVVESESSION_SET_PUBLISHER_ID',
  LIVVESESSION_SET_PUBLISHER_PRIVATE_ID = 'LIVVESESSION_SET_PUBLISHER_PRIVATE_ID',
  LIVESESSION_SET_REMOTES = 'LIVESESSION_SET_REMOTES',
  LIVESESSION_SET_ERROR_IN_CAPTURE_IMAGE = 'LIVESESSION_SET_ERROR_IN_CAPTURE_IMAGE',

  LIVESESSION_UNSET_REMOTE = 'LIVESESSION_UNSET_REMOTE',

  LIVESESSION_SET_LOCAL_STREAM = 'LIVESESSION_SET_LOCAL_STREAM',

  //   Session Information
  LIVESESSION_SET_ROOM = 'LIVESESSION_SET_ROOM',

  // Chat
  LIVESESSION_SET_CHAT_MESSAGE = 'LIVESESSION_SET_CHAT_MESSAGE',
}
