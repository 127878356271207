import styled from 'styled-components';

import theme from 'theme';

const { colors, mediaQueries } = theme;

export const Container = styled.section`
  height: 100%;
  background: linear-gradient(
      0deg,
      ${colors.blackOpacity(0.15)},
      ${colors.blackOpacity(0.15)}
    ),
    radial-gradient(
      97.57% 210.75% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.355) 0%,
      rgba(255, 255, 255, 0.02) 100%
    );
  backdrop-filter: blur(20px);
  border-radius: 10px;
  width: 100%;
  flex: 1;
  max-width: 570px;
  min-height: 621.5px;
  max-height: 621.5px;
  display: none;

  ${mediaQueries.smallScreen} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 38px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
`;
