import React, { createContext, useContext, useState } from 'react';

interface ISMSContent {
  smsCode: string | null;
  setSMSCode: (s: string | null) => void;
}

const SmsContext = createContext<ISMSContent>({
  smsCode: null,
  setSMSCode: () => {},
});

interface ISMSProvider {
  children: JSX.Element;
}

const SmsProvider = ({ children }: ISMSProvider) => {
  const [smsCode, setSMSCode] = useState<string | null>(null);

  return (
    <SmsContext.Provider value={{ smsCode, setSMSCode }}>
      {children}
    </SmsContext.Provider>
  );
};

const useSMSContext = () => useContext(SmsContext);

export default {
  useSMSContext,
  SmsProvider,
};
