import { GlobalInterface } from 'interfaces/globalConstants';

export enum Roles {
  PATIENT = 1,
  HOST = 2,
  SUPER_HOST = 3,
  CONCERGE = 4,
  ADMIN = 5,
}

const globals: GlobalInterface = {
  backend: '',
  // uriAPI: 'http://localhost:3001', // development
  uriAPI: 'https://backend-staging.noyaview.com', // staging
  // uriAPI: '', // prod
  landingUrl: 'https://landing.noyaview.com',
  REACT_APP_SECRET_SMS_PRHASE: 'secretEncryptionPhrase',
  janus_endpoint: 'http://localhost:8088/janus',
};

export default globals;
