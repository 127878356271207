import styled from 'styled-components';

import theme from 'theme';

const { mediaQueries, colors } = theme;

export const StyleBrand = styled.div`
  transition: all 350ms;
`;

export const NavStyle = styled.div`
  &.menu {
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5vw;
    background-color: ${colors.darkBlue};
    gap: 0 30px;
    z-index: 999999;
    ${mediaQueries.mediumScreen} {
      max-height: 75px;
      padding: 5px 5vw;
      justify-content: flex-start;
    }
  }
`;

export const NavLogo = styled.a`
  cursor: pointer;
`;

export const ListStyle = styled.ul`
  display: none;
  list-style: none;
  justify-content: space-between;
  gap: 30px;

  ${mediaQueries.mediumScreen} {
    display: flex;
  }
`;

export const HamburguerStyle = styled.div`
  cursor: pointer;
  ${mediaQueries.mediumScreen} {
    display: none;
  }
`;

export const WrapperMobileMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 53px;
  padding-bottom: 70px;
  background-color: ${colors.darkBlue};
  ${mediaQueries.mediumScreen} {
    display: none;
  }
`;

export const WrapperMobileMenuFixed = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 0;
  background-color: ${colors.darkBlue};
  z-index: 999998;
  ${mediaQueries.mediumScreen} {
    display: none;
  }
`;

export const ListStyleMobile = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-between;
  gap: 30px 10px;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  padding: 0 25px;

  li {
    width: calc(50% - 20px);

    p {
      width: fit-content;
      text-align: center;
      margin: 0 auto;
      padding: 0 0 10px;
    }
  }
`;
