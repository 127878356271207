import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.861rem;
  gap: 0.5rem;
  background: #1e2745;
  border-radius: 43.9951px;
  padding: 14.29px;
  align-items: center;
`;

export const Input = styled.input`
  border: none;
  background: transparent;
  outline: none;
`;

export const Send = styled.img`
  width: 13.77px;
  height: 13.77px;
  cursor: pointer;
`;
