import styled, { keyframes } from 'styled-components';
import theme from 'theme';
import ArrowDownIcon from 'assets/images/pngs/arrow_down_white.png';

const { colors } = theme;

interface ISectionContainer {
  position: 'center' | 'end' | 'start';
}

export const SectionContainer = styled.section<ISectionContainer>`
  display: flex;
  column-gap: 0.688rem;
  justify-content: ${({ position }) => position};
  align-items: center;
`;

export const Select = styled.select`
  box-sizing: border-box;

  width: 157px;
  height: 45px;
  padding: 12px 20px;
  padding-right: 40px;

  background: rgba(19, 21, 23, 0.63);
  backdrop-filter: blur(3.15px);
  border-radius: 33.6px;

  outline: none;
  color: ${colors.white};
  font-size: 14px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background: url(${ArrowDownIcon});
  background-position: calc(100% - 8px) center;
  background-size: 14px;
  background-repeat: no-repeat;
  background-color: rgba(19, 21, 23, 0.63);
`;

const heartAnimation = keyframes`
  to {
    transform: scale(1.2);
  }

  from {
    transform: scale(1);
  }
`;

export const RecorderButton = styled.img`
  animation: 1s ${heartAnimation} infinite;
`;
