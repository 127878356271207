import styled from 'styled-components';
import colorsPalette from 'theme/colors';
import Text from '../Text';

export const TypeMode = styled.div`
  display: grid;
  grid-template-columns: 111px max-content 144px;
  gap: 4px;
  align-items: center;
  color: #7b809a;
  place-content: center;
`;

export const SwitchContainer = styled.div`
  background: ${colorsPalette.white};
  border-radius: 19.9652px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4.86957px 6.33043px;
  gap: 6.33px;
`;

export const SwitchItem = styled.div`
  width: 14.61px;
  height: 14.61px;
  border-radius: 50%;
  background: rgba(120, 120, 120, 0.4);
  cursor: pointer;

  &.active {
    background: #366bb3;
  }
`;

export const SepareSwitchItem = styled.div`
  width: 1.1px;
  height: 13.18px;
  background: #a3a3a3;
  border-radius: 0.973913px;
`;

export const Label = styled(Text)`
  color: #7b809a;

  &.left {
    text-align: end;
  }

  &.right {
    text-align: start;
  }

  &.active {
    color: #366bb3;
  }
`;
