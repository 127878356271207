import styled from 'styled-components';

import theme from 'theme';

const { mediaQueries, colors } = theme;

export const Container = styled.main`
  background-color: ${colors.white};
  ${mediaQueries.smallScreen} {
  }
`;

export const NotFoundText = styled.div`
  background-color: ${colors.darkBlue};
  ${mediaQueries.smallScreen} {
  }
`;

export const GoToLoginButton = styled.span`
  background-color: ${colors.white};
  ${mediaQueries.smallScreen} {
  }
`;
