import styled from 'styled-components';
import colorsPalette from 'theme/colors';

export const MainContent = styled.div`
  position: relative;
  border-radius: 0.5px;
  width: 100%;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  gap: 2px;
  background: #f8f8f8;
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 2px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 43px;
`;

export const ItemStyle = styled.div`
  position: relative;
  background: #e7e7e7;
  border-radius: 4px 4px 0px 0px;
  max-width: 158px;
  height: 36px;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 6px;
  gap: 5px;
  cursor: pointer;

  &.active {
    background: #597dc9;
    color: ${colorsPalette.white};
    &::after {
      display: flex;
      content: '';
      position: absolute;
      left: 50px;
      width: 30px;
      background: #597dc9;
      // background: red;
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
      transition: height 0.3s, top 0.3s;
      transform: rotateZ(180deg);
      left: calc(50% - 15px);
      margin: auto;
      margin-top: 40px;
      height: 10px;
      z-index: 1;
    }
  }

  & > svg {
    cursor: pointer;
  }
`;

export const Line = styled.div`
  height: 1px;
  background: #d0d0d0;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const PlusButton = styled.div`
  width: 21.66px;
  height: 25.66px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 8px;
  cursor: pointer;
  color: #707070;

  &:hover {
    background: #597dc9;
    border-radius: 4px;
    color: ${colorsPalette.white};
  }

  & > svg {
    transform: scale(2);
  }
`;
