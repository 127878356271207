import styled from 'styled-components';
import fontsFamily from 'theme/fonts';

export const ExpandTwoColumns = styled.div`
  grid-column: 1 / 3;
`;

export const SubElements = styled.div`
  padding-left: 1.1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 3px;
  align-items: center;
`;

export const Input = styled.input`
  font-family: ${fontsFamily.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5b5b5b;
  padding: 3.5px 1.5px;
`;
