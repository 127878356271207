import styled from 'styled-components';
import colorsPalette, { appTheme, TypesPallete } from 'theme/colors';
import fontsFamily from 'theme/fonts';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  min-height: 120px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 43px 1fr;
`;

export const PatientImage = styled.img`
  width: 100%;
  max-width: 120px;
  height: 100%;
  min-height: 120px;
  border-radius: 50%;
  position: absolute;
  left: 0;
`;

interface IHeader {
  pallete: TypesPallete;
}

export const Header = styled.div<IHeader>`
  height: 43px;
  margin-left: calc(120px / 2);
  padding-left: calc((120px / 2) + 12px);
  background: ${({ pallete }) => appTheme[pallete].gradient_patient_card};
  border-top-right-radius: 16px;
  display: flex;
  align-items: center;
`;

export const Body = styled.div`
  border-bottom-right-radius: 16px;
  margin-left: calc(120px / 2);
  padding-left: calc((120px / 2) + 12px);
  display: grid;
  grid-template-columns: 1fr max-content;
  font-family: ${fontsFamily.manrope};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  padding-top: 10.89px;
  padding-bottom: 5px;
  padding-right: 40px;
  box-shadow: 0px 4px 6px rgba(89, 126, 202, 0.7);
`;

export const PersonalInformation = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 300px;
  width: 100%;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-size: 8px;
`;

interface IStatus {
  status: 'Active';
}

const StatusColor = {
  Active: '#047857',
};

export const Status = styled.label<IStatus>`
  color: ${({ status }) => StatusColor[status]};
  font-family: ${fontsFamily.manrope};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
`;

export const IconMenu = styled.img`
  width: 4px;
  height: 16px;
`;

export const DropDownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 43px;
  background: ${colorsPalette.blueAquaOpacity(0.47)};
  box-shadow: 0px 4px 4px ${colorsPalette.blackOpacity(0.25)};
  backdrop-filter: blur(7.5px);
  border-radius: 0px 0px 8px 8px;
  height: 228px;
  width: 207px;
  padding: 15px 10px;
  color: ${colorsPalette.white};
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10;
`;

export const DropdownItemContainer = styled.div`
  display: grid;
  grid-template-columns: 10px 24px 1fr;
  gap: 12px;
  align-items: center;
`;

export const ActiveElement = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${colorsPalette.white};
`;
