import React, { useState } from 'react';

import EyeShow from 'assets/images/pngs/eye-show.png';
import EyeHidden from 'assets/images/pngs/eye-hidden.png';

import { TypesColors } from 'theme/colors';

import Text from '../Text';

import { Container, InputContainer, InputIcon, InputStyled } from './style';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  bgColor?: TypesColors;
  removeMaxHeight?: boolean;
}

const Input = (
  { label, type, removeMaxHeight, ...input }: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const [typeInput, setTypeInput] = useState(type);

  const handleChangeState = () => {
    if (typeInput === 'password') {
      setTypeInput('text');
    } else {
      setTypeInput('password');
    }
  };

  return (
    <Container>
      {label && <Text variant="POPPINS_13_400">{label}</Text>}
      <InputContainer removeMaxHeight={removeMaxHeight}>
        <InputStyled
          type={typeInput}
          {...input}
          removeMaxHeight={removeMaxHeight}
          ref={ref}
        />
        {type === 'password' && (
          <InputIcon
            src={typeInput === 'password' ? EyeShow : EyeHidden}
            alt="Show"
            onClick={handleChangeState}
          />
        )}
      </InputContainer>
    </Container>
  );
};

export default React.forwardRef(Input);
