import styled from 'styled-components';

import theme from 'theme';

import Background from 'assets/images/pngs/background-image.png';

const { mediaQueries } = theme;

export const Container = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ForgotContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueries.smallScreen} {
    background: linear-gradient(
        67.13deg,
        rgba(92, 183, 112, 0.2) 2.91%,
        rgba(126, 77, 129, 0.2) 82.63%
      ),
      url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PrincipalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${mediaQueries.smallScreen} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
