import { Janus } from 'shared/helpers/Janus/Janus_GateWay';
import { LiveSessionTypes } from '../types/livessesion_types';

// Desectructure Live Session Types
const {
  LIVESESSION_SET_DEVICES,
  LIVESESSION_SET_JANUS_INSTANCE,
  LIVESESSION_SET_REMOTES,
  LIVVESESSION_SET_PUBLISHER_ID,
  LIVVESESSION_SET_PUBLISHER_PRIVATE_ID,
  LIVESESSION_SET_ROOM,
  LIVESESSION_UNSET_REMOTE,
  LIVESESSION_SET_JANUS_ROOM_INSTANCE,
  LIVESESSION_SET_ERROR_IN_CAPTURE_IMAGE,
  LIVESESSION_SET_CHAT_MESSAGE,
  LIVESESSION_SET_LOCAL_STREAM,
} = LiveSessionTypes;

// devices
export const setDevices = (devices: any) => ({
  type: LIVESESSION_SET_DEVICES,
  value: devices,
});

// janus
export const setJanusInstance = (instance: Janus) => ({
  type: LIVESESSION_SET_JANUS_INSTANCE,
  value: instance,
});
export const setJanusRoomInstance = (instance: Janus) => ({
  type: LIVESESSION_SET_JANUS_ROOM_INSTANCE,
  value: instance,
});

export const setLocalStream = (stream: any) => ({
  type: LIVESESSION_SET_LOCAL_STREAM,
  value: stream,
});

export const setUnsetRemote = (id: number) => ({
  type: LIVESESSION_UNSET_REMOTE,
  value: id,
});

export const setPublisherId = (publisherId: number) => ({
  type: LIVVESESSION_SET_PUBLISHER_ID,
  value: publisherId,
});

export const setPublisherPrivateId = (publisherPrivateId: number) => ({
  type: LIVVESESSION_SET_PUBLISHER_PRIVATE_ID,
  value: publisherPrivateId,
});

export const setRemotes = (publishers: any[]) => ({
  type: LIVESESSION_SET_REMOTES,
  value: publishers,
});

export const setErrorInCaptureImage = (isError: boolean) => ({
  type: LIVESESSION_SET_ERROR_IN_CAPTURE_IMAGE,
  value: isError,
});

// Room
export const setRoom = (room: number) => ({
  type: LIVESESSION_SET_ROOM,
  value: room,
});

// Chat
export const setMessage = (message: any) => ({
  type: LIVESESSION_SET_CHAT_MESSAGE,
  value: message,
});
