import {
  IPaginationPatient,
  IPatientInfo,
  IPatient,
  IPatientBilling,
} from 'interfaces/Patient';
import PatientResponse from 'shared/exampleResponse/Patients.json';
import PatientInfoResponse from 'shared/exampleResponse/PatientInfo.json';

export const PatientService = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAllPatient: async (search: string): Promise<IPaginationPatient> =>
    PatientResponse as IPaginationPatient,
  getById: async (patientId: number): Promise<IPatient | undefined> => {
    return (PatientResponse as IPaginationPatient).data.find(
      ({ id }) => id === patientId
    );
  },
  getInfoById: async (
    patientId: number | undefined
  ): Promise<IPatientInfo | undefined> => {
    const patient = PatientInfoResponse.find(({ id }) => id === patientId);

    return patient?.data;
  },
  getBillingById: async (patientId: number): Promise<IPatientBilling> => {
    console.log(patientId);

    return {
      copayForRealVisit: '',
      copayForVirtualVisit: '',
      dentalPlan: '',
      mrNumber: '243332',
      nameOfTheListedHealthPlan: '',
      patientVisitCharge: '',
      primaryInsurance: 'Blue Shield PPO Platinum 0/0',
      secondaryInsurance: '',
      tertiaryInsurance: '',
      virtualVisitCharge: '',
      visionPlan: '',
      billingType: 'Insurance',
    };
  },
};
