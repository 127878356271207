import styled from 'styled-components';

import theme from 'theme';

const { colors, fontsFamily } = theme;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 10px 60px;
`;

export const Main = styled.div`
  width: 100%;
  max-width: 630px;
  position: relative;
`;

export const ColumnsContent = styled.div`
  width: 100%;
  padding: 2.5vh 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  width: calc(50% - 11px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Separator = styled.div`
  width: 1px;
  height: 250px;
  margin: 0 5px;
  background: ${colors.gray};
  border-radius: 75px;
`;

export const CardOption = styled.div`
  width: 220px;
  height: 65px;
  display: flex;
  margin: 10px 0;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;

  &.selected {
    background: ${colors.whiteOpacity(0.47)};
    border: 1px solid ${colors.grayPlane};
    box-shadow: 0px 4px 4px ${colors.blackOpacity(0.25)};
    backdrop-filter: blur(2px);
    border-radius: 12px;
  }

  svg {
    display: inline-block;
  }
`;

export const NameTheme = styled.span`
  font-family: ${fontsFamily.inter};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.grayPlane};
  margin-left: 20px;
`;

export const ButtonsDown = styled.div`
  gap: 40px;
  width: 100%;
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
`;

export const ImageTheme = styled.img`
  border-radius: 12px;
`;
