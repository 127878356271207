import styled from 'styled-components';

import theme from 'theme';

import { TypesColors } from 'theme/colors';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`;

interface InputContainerProps {
  removeMaxHeight?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  width: 100%;
  position: relative;
  ${({ removeMaxHeight }) => removeMaxHeight && 'height: 100%;'}
`;

interface InputProps {
  bgColor?: TypesColors;
  textColor?: TypesColors;
  removeMaxHeight?: boolean;
}

export const InputStyled = styled.input<InputProps>`
  width: 100%;
  height: ${({ removeMaxHeight }) => (removeMaxHeight ? '100%' : '44px')};
  border-radius: 8px;
  color: ${colors.black};
  outline: none;
  padding: 1rem;
  background: ${colors.whiteInputs};
  &[type='checkbox'] {
    width: 19px;
    height: 19px;
    accent-color: ${colors.white};
    border: 0;
    outline: none;
    box-shadow: ${colors.shadow};
    display: flex;
  }
  &[type='submit'] {
    background: ${({ bgColor }) => (bgColor ? colors[bgColor] : colors.green)};
    cursor: pointer;
    color: ${({ textColor }) => (textColor ? colors[textColor] : colors.white)};
    border-radius: 13px;
    font-size: 25px;
    padding: ${({ removeMaxHeight }) => (removeMaxHeight ? '12px' : '0')};
    font-family: 'Poppins';
    font-weight: 700;
  }

  &:not(.hide-hints):invalid {
    border: 1px solid ${colors.red};
  }
`;

export const InputIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;
