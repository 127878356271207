import styled from 'styled-components';

export const ChatRoomContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChatRoomContainerMessage = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow-y: auto;
  flex: 1;
  padding-bottom: 2rem;
  flex-basis: 0;
  overflow-y: auto;
`;
