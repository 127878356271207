import styled, { css } from 'styled-components';

import theme from 'theme';
import { TypesColors } from 'theme/colors';

const { fontsFamily, colors } = theme;

export type VariantTitle =
  | 'HELVETICA_45_700'
  | 'HELVETICA_45_400'
  | 'HELVETICA_19_700'
  | 'HELVETICA_33_700'
  | 'HELVETICA_20_700'
  | 'HELVETICA_26_400'
  | 'POPPINS_35_400'
  | 'POPPINS_35_700'
  | 'INTER_22_600'
  | 'INTER_32_600'
  | 'INTER_16_700'
  | 'INTER_48_700';

export const cssTextVariant = {
  HELVETICA_45_700: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 45px;
    line-height: 51px;
  `,
  HELVETICA_45_400: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 400;
    font-size: 45px;
    line-height: 48px;
  `,
  HELVETICA_39_700: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 700;
    font-size: 39px;
    line-height: 59px;
  `,
  HELVETICA_19_700: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 19px;
    line-height: 48px;
  `,
  HELVETICA_33_700: css`
    font-family: ${fontsFamily.helveticaBold};
    font-weight: 700;
    font-size: 33px;
    line-height: 48px;
  `,
  HELVETICA_20_700: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  HELVETICA_26_400: css`
    font-family: ${fontsFamily.helvetica};
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
  `,
  POPPINS_35_400: css`
    font-family: ${fontsFamily.poppins};
    font-weight: 400;
    font-size: 35px;
    line-height: 135.5%;
  `,
  POPPINS_35_700: css`
    font-family: ${fontsFamily.poppins};
    font-weight: 700;
    font-size: 35px;
    line-height: 135.5%;
  `,
  INTER_22_600: css`
    font-family: ${fontsFamily.inter};
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
  `,
  INTER_32_600: css`
    font-family: ${fontsFamily.inter};
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
  `,
  INTER_16_700: css`
    font-family: ${fontsFamily.inter};
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  `,
  INTER_48_700: css`
    font-family: ${fontsFamily.inter};
    font-weight: 700;
    font-size: 48px;
    line-height: 140%;
  `,
};

interface ITitleProps {
  $variant: VariantTitle;
  $color?: TypesColors;
}

export const TitleStyle1 = styled.h1<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;

export const TitleStyle2 = styled.h2<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;

export const TitleStyle3 = styled.h3<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;

export const TitleStyle4 = styled.h4<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;

export const TitleStyle5 = styled.h5<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;

export const TitleStyle6 = styled.h6<ITitleProps>`
  ${({ $variant }) => cssTextVariant[$variant]}
  ${({ $color }) => $color && `color: ${colors[$color]}`};
`;
