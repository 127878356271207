import styled from 'styled-components';
import colorsPalette from 'theme/colors';

// export const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 23px;
//   align-items: center;
// `;

export const Options = styled.div`
  background: ${colorsPalette.white};
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  &.disable {
    background: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
    & > * {
      cursor: not-allowed;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  &.active {
    background: #366bb3;
    border-radius: 8px;
    color: #fff;
  }

  &.disable {
    color: #777777;
    cursor: not-allowed;
  }
`;
