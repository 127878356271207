import styled from 'styled-components';

export const Sidebar = styled.section`
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
`;

interface IContainer {
  top: number;
}

export const Container = styled.div<IContainer>`
  margin-top: ${({ top }) => top + 48}px;
  text-align: center;
  background: #071236;
  height: 100%;
  flex: 1;
  border-radius: 17.598px 17.598px 0 0;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  padding: 12px 17.6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #343637;
`;

export const Close = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Body = styled.div`
  padding: 25.2px 17.6px;
  height: 100%;
`;
