/* eslint-disable max-len */
import React from 'react';

import theme from 'theme';

import ContextTheme from 'shared/globalStates/contextTheme';

import useTopPosition from 'shared/hooks/useTopPosition';

import { Calendar } from './Calendar';

import {
  SidebarStyled,
  ToggleBarStyled,
  TitleDoctorName,
  ExpandStyleContainer,
  AddScheduleSessionStyled,
  MainContainer,
  FloatingButton,
} from './style';

const { colors } = theme;

interface ISidebar {
  showCalendar?: boolean;
  setShowCalendar?: (b: boolean) => void;
}

const CalendarSidebar = ({ showCalendar, setShowCalendar }: ISidebar) => {
  const { palette } = ContextTheme.useThemeContext();

  const topPosition = useTopPosition();

  const updateCalendarState = () => {
    if (setShowCalendar) setShowCalendar(!showCalendar);
  };

  return (
    <MainContainer>
      <ExpandStyleContainer className="z-49">
        {showCalendar ? (
          <ToggleBarStyled
            className="toggle__expand contract flex text-white items-center cursor-pointer z-50"
            onClick={() => updateCalendarState()}
            $palette={palette}
          >
            <svg
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.11783e-08 1.85714L5 6.5L4.8707e-07 11.1429L1 13L8 6.5L1 -4.9254e-08L8.11783e-08 1.85714Z"
                fill="white"
              />
            </svg>
          </ToggleBarStyled>
        ) : (
          <>
            <FloatingButton onClick={updateCalendarState}>
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1.85714L3 6.5L8 11.1429L7 13L-2.84124e-07 6.5L7 -4.9254e-08L8 1.85714Z"
                  fill="white"
                />
              </svg>

              <div className="icon__calendar">
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 28 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.8889 3.1H23.3333V0H20.2222V3.1H7.77778V0H4.66667V3.1H3.11111C1.38444 3.1 0.0155555 4.495 0.0155555 6.2L0 27.9C0 28.7222 0.327777 29.5107 0.911223 30.092C1.49467 30.6734 2.28599 31 3.11111 31H24.8889C26.6 31 28 29.605 28 27.9V6.2C28 4.495 26.6 3.1 24.8889 3.1ZM24.8889 27.9H3.11111V12.4H24.8889V27.9ZM24.8889 9.3H3.11111V6.2H24.8889V9.3ZM9.33333 18.6H6.22222V15.5H9.33333V18.6ZM15.5556 18.6H12.4444V15.5H15.5556V18.6ZM21.7778 18.6H18.6667V15.5H21.7778V18.6ZM9.33333 24.8H6.22222V21.7H9.33333V24.8ZM15.5556 24.8H12.4444V21.7H15.5556V24.8ZM21.7778 24.8H18.6667V21.7H21.7778V24.8Z"
                    fill="white"
                  />
                </svg>
              </div>
            </FloatingButton>
          </>
        )}
      </ExpandStyleContainer>
      {showCalendar && (
        <>
          <SidebarStyled
            className={`navigation__bar right-0 py-10 text-white overflow-y-auto ${
              showCalendar ? '' : ''
            }`}
          >
            <div className="space-y-3">
              <div className="flex-1">
                <TitleDoctorName level={4} color="white" variant="INTER_16_700">
                  Sandeep Khanna’s Calendar
                </TitleDoctorName>
                <Calendar
                  language={'es-ES'}
                  padding="10px"
                  bgColor={colors.whiteOpacity(0.2)}
                  bgMonth="transparent"
                  borderRadius="8px"
                  colorArrows="#FFF"
                  colorTextHeader={colors.black}
                  colorTextdaysOfTheWeek={colors.white}
                  colorDays={colors.black}
                  wDay="25px"
                  hDay="25px"
                  sizeArrow="25"
                  fontWeightMonthAndYear="bold"
                />
              </div>
            </div>
          </SidebarStyled>
          <AddScheduleSessionStyled
            $themeSelected={palette}
            $topPx={topPosition}
          >
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.2955 15.4249H2.55894M15.4272 2.55664V28.2932"
                stroke="white"
                strokeWidth="3.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </AddScheduleSessionStyled>
        </>
      )}
    </MainContainer>
  );
};

export default CalendarSidebar;
