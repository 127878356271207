import styled from 'styled-components';
import colorsPalette from 'theme/colors';
import fontsFamily from 'theme/fonts';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const SearchProvider = styled.div`
  position: relative;
  display: flex;
  padding: 10px 20px;
  gap: 11px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: ${colorsPalette.white};
  width: 233px;
  align-items: center;
  height: 36px;

  &.disabled {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #ffffff;
    color: #858585;
    cursor: not-allowed;

    & > * {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.05),
          rgba(0, 0, 0, 0.05)
        ),
        #ffffff;
      color: #858585;
      cursor: not-allowed;
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  font-family: ${fontsFamily.mulish};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  min-width: 50px;
`;

export const SearchImgIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const ContainerResults = styled.div`
  position: absolute;
  background: ${colorsPalette.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  top: calc(36px + 10px);
  left: 0;
  width: 100%;
  height: 200px;
  z-index: 1;
  padding: 10px 4px;
  overflow-y: auto;
`;

export const ResultItem = styled.div`
  border-bottom: 0.315461px solid rgba(209, 209, 209, 0.5);
  padding: 4px calc(16.64px - 4px);
  display: flex;

  &:last-child {
    border: none;
  }
`;

export const Checkbox = styled.input`
  // Remove Apparence
  -webkit-appearance: none;
  appearance: none;

  // Add design
  background: ${colorsPalette.white};
  border: 2px solid #4071d7;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  place-content: center;

  &:checked {
    background: #4071d7;
  }

  &:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 4px;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }
`;

export const ItemSelected = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 8px;

  max-width: 91px;
  width: max-content;
  height: 20px;

  background: #4d73a6;
  border-radius: 4px;
  color: ${colorsPalette.white};
`;
