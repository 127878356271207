import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 10px 0;
  overflow-y: auto;
  display: flex;
`;

export const MainContent = styled.div`
  background: rgba(255, 255, 255, 0.27);
  // backdrop-filter: blur(20px);
  // -webkit-backdrop-filter: blur(20px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.27);
  backdrop-filter: blur(75px);
  -webkit-backdrop-filter: blur(75px);

  border-radius: 16px;
  height: max-content;
  width: 100%;
  max-width: 786px;
  margin: auto;
`;
