import styled from 'styled-components';

import theme from 'theme';
import { TypesPallete } from 'theme/colors';

import Text from 'components/atoms/Text';
import { NavLink } from 'react-router-dom';

const { colors } = theme;

export const TextStyle = styled(Text)``;

interface IWrapProps {
  $isExpand?: boolean;
}

export const WrappStyle = styled(NavLink)<IWrapProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ $isExpand }) => ($isExpand ? 'flex-start' : 'center')};

  &.selected__submain {
    background-color: ${colors.whiteOpacity(0.4)};
    border-radius: 8px;
  }
`;

interface IItemProps {
  $palette?: TypesPallete;
  $isExpand?: boolean;
}

export const ItemStyle = styled.button<IItemProps>`
  cursor: pointer;

  p {
    padding: 10px 0;
    color: ${colors.white};
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
  }

  label {
    display: flex;
    flex-direction: row;
    justify-content: ${({ $isExpand }) =>
      $isExpand ? 'flex-start' : 'center'};
    align-items: center;
    cursor: pointer;

    svg {
      width: ${({ $isExpand }) => ($isExpand ? '25px' : '20px')};
      height: ${({ $isExpand }) => ($isExpand ? '25px' : '20px')};
      color: ${colors.white};
      fill: ${colors.white};
    }
  }

  &.selected__submain {
    p {
      padding: 10px 0;
      ${({ $palette }) =>
        $palette && `color: ${colors.appTheme[$palette].secondary}`};
    }

    label {
      svg {
        ${({ $palette }) =>
          $palette && `color: ${colors.appTheme[$palette].secondary}`};
        ${({ $palette }) =>
          $palette && `fill: ${colors.appTheme[$palette].secondary}`};
      }
    }
  }

  &:hover {
  }
`;

export const AncleStyle = styled.label``;
