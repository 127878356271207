import styled from 'styled-components';

import theme from 'theme';
import { TypesPallete } from 'theme/colors';

const { colors, mediaQueries } = theme;

export const Container = styled.nav`
  grid-area: nav;
  max-width: 239px;
`;

export const NavigationBarStyled = styled.div`
  &.navigation__bar {
    height: calc(100vh - 88px);

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
`;

export const ExpandStyleContainer = styled.div`
  position: relative;
  width: 100%;
`;

interface IToggle {
  $palette?: TypesPallete;
}

export const ToggleBarStyled = styled.button<IToggle>`
  &.toggle__expand {
    position: absolute;
    top: 10px;
    width: 25px;
    height: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    font-size: 12px;

    &.expand {
      left: 35%;

      ${mediaQueries.xLargeScreen} {
        left: 25%;
      }
    }

    &.contract {
      right: 0px;
      left: none;
    }

    &:hover {
      border-radius: 75px;
      background-color: ${({ $palette }) =>
        $palette ? colors.appTheme[$palette].primary : 'transparent'};
    }
  }
`;

export const CapsuleStyled = styled.li`
  &.bar__capsule {
    width: 100%;
    min-width: 60px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.active {
      background-color: ${colors.whiteOpacity(0.4)};
    }

    &:hover {
    }
  }
`;
