import React from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ContextAuth from 'shared/globalStates/contextAuth';
import ContextTheme from 'shared/globalStates/contextTheme';
import { ModalProvider } from 'shared/globalStates/contextModal';
import { PatientProvider } from 'shared/globalStates/contextPatient';
import ComingSoon from 'pages/ComingSoon';

import GlobalStyle from './theme/general';

import { MainComponent } from './navigation';

function App() {
  console.log('Enviroment 🚀:', process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === 'production') {
    return <ComingSoon />;
  }
  return (
    <>
      <GlobalStyle />
      <ModalProvider>
        <ContextAuth.AuthProvider>
          <ContextTheme.ThemeProvider>
            <PatientProvider>
              <MainComponent />
            </PatientProvider>
          </ContextTheme.ThemeProvider>
        </ContextAuth.AuthProvider>
      </ModalProvider>
    </>
  );
}

export default App;
