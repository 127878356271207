export const canPIP = (): boolean =>
  'pictureInPictureEnabled' in document && document.pictureInPictureEnabled;

export const activePIP = async (
  video: HTMLVideoElement,
  callbackCamera: () => void,
  callbackMicrophone: () => void
): Promise<PictureInPictureWindow> => {
  if (!canPIP()) {
    throw new Error('No supported');
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator.mediaSession.setActionHandler('togglecamera', callbackCamera);

  navigator.mediaSession.setActionHandler(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    'togglemicrophone',
    callbackMicrophone
  );

  const resp = await video.requestPictureInPicture();

  return resp;
};

export const hiddenPIP = async (): Promise<void> => {
  if (!canPIP()) {
    throw new Error('No supported');
  }

  const resp = await document.exitPictureInPicture();
  return resp;
};
