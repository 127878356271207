import styled from 'styled-components';

export const Container = styled.header`
  position: absolute;
  top: 26px;
  left: 23px;
  right: 23px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.313rem;
  z-index: 10;
`;
