import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SectionContainer = styled.section`
  background: #f8f8f8;
  border-radius: 16px;
  width: 100%;
  padding: 5px 16px 10px;
  color: #535353;
  display: flex;
  filter: drop-shadow(0px 62px 25px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 35px 21px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
`;

export const EditIcon = styled.button`
  width: 19px;
  height: 20px;
`;

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  margin-top: 47px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
`;
