import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

import Text from 'components/atoms/Text';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const TextSubtitle = styled(Text)`
  margin: 10vh 0px;
`;

interface InputContainerProps {
  isShowAlert: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  margin-top: 29px;
  margin-bottom: ${({ isShowAlert }) => (isShowAlert ? '5px' : '59px')};
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  text-align: left;
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  gap: 0.5rem;
`;

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1.18rem 1fr;
  gap: 1rem;
  width: 100%;
`;

export const AlertContainer = styled.span`
  margin-top: 22px;
  margin-bottom: 22px;
  text-align: left;
`;

export const NavLinkStyle = styled(NavLink)`
  color: unset;
  font-size: 14px;
`;

export const OtherContainer = styled.div`
  margin-top: 29px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.31rem;
`;
