import styled, { keyframes } from 'styled-components';
import theme from 'theme';

const { fontsFamily } = theme;

const getCoord = () => {
  const search = document.getElementById('search');

  const coord = search?.getBoundingClientRect().top || 120;

  return coord;
};

// Animation container
const openSearch = keyframes`
    50% {
        width: 100%;
        height: 50px;
    }

    100% {
        width: 100%;
        // height: 90%;
        height: calc(90vh - ${getCoord() + scrollY}px);
    }
`;

const closeSearch = keyframes`
    0% {
        width: 100%;
        height: calc(90vh - ${getCoord() + scrollY}px);
    }
    50% {
        height: 50px;
        width: 100%;
    }

    100% {
        width: 50px;
        height: 50px;
    }
`;

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.13);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 9999;

  border-radius: 16px;
  width: 50px;
  height: 50px;
  box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  &.close_true {
    animation: ${closeSearch} 2s forwards;
  }

  &.open {
    animation: ${openSearch} 2s forwards;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 13px;
  column-gap: 13px;
  height: 50px;
  &.border {
    border-bottom: 1px solid #d9d9d9;
    border-radius: 16px 16px 0 0;
  }
`;

export const SearchImage = styled.img`
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

export const Input = styled.input`
  font-family: ${fontsFamily.inter};
  font-size: 20px;
  line-height: 24px;
  flex: 1;
  outline: none;
  background: transparent;
`;

// Body
export const Body = styled.div`
  padding: 13px 11px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface IVisibleContent {
  visibility: 'visible' | 'hidden';
}

export const FilterContainer = styled.div<IVisibleContent>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  visibility: ${({ visibility }) => visibility};
`;

export const TableContainer = styled.div<IVisibleContent>`
  margin-top: calc(44px + 27px);
  text-aling: right;
  display: flex;
  flex-direction: column;
  flex: 1;
  visibility: ${({ visibility }) => visibility};
`;
