import styled from 'styled-components';

import Background from 'assets/images/pngs/bg-admin.png';

import theme from 'theme';
import { TypesPallete } from 'theme/colors';

const { mediaQueries, colors } = theme;

interface IContainer {
  $theme?: TypesPallete;
}

export const Container = styled.main<IContainer>`
  display: flex;
  flex-direction: column;
  background: ${({ $theme }) =>
    $theme === '1'
      ? `linear-gradient(
        180deg,
        rgba(4, 85, 191, 0.8) 55%,
        rgba(10, 140, 191, 0.75) 75%
        ),
        url(${Background})`
      : $theme === '2'
      ? `linear-gradient(
        180deg, 
        rgba(48,110,191,0.8) 55%, 
        rgba(61,173,217,0.75) 75%),
        url(${Background})`
      : $theme === '3'
      ? ` linear-gradient(
        180deg, 
        rgba(2,40,115,0.8) 55%, 
        rgba(5,199,242,0.75) 75%),
        url(${Background})`
      : $theme === '4'
      ? `linear-gradient(
        180deg, 
        rgba(22,34,127,0.8) 55%, 
        rgba(61,68,127,0.75) 75%),
        url(${Background})`
      : `linear-gradient(
        180deg,
        rgba(4, 85, 191, 0.8) 55%,
        rgba(10, 140, 191, 0.75) 75%
        ),
        url(${Background})`};
  background-repeat: no-repeat;
  background-size: cover;
  ${mediaQueries.smallScreen} {
  }
`;

export const Glassmorphism = styled.div`
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(75px);
  background: ${colors.whiteOpacity(0.1)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;

  ${mediaQueries.smallScreen} {
  }
`;
