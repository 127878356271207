import styled from 'styled-components';

interface IContainer {
  isVisible: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  width: 100%;
  position: relative;
  height: 100%;
  border-radius: 21px;
  ${(props) =>
    !props.isVisible
      ? `
    visibility: hidden;
    position: fixed;
    top: 0;
  `
      : `
  position: relative;
  `}
`;

export const VideoStyle = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 21px;
`;

export const ExpandIconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;
