import styled from 'styled-components';
import colorsPalette from 'theme/colors';
import fontsFamily from 'theme/fonts';

export const TextAreaInput = styled.textarea`
  background: ${colorsPalette.white};
  border-radius: 7.03922px;
  padding: 16px 10px;
  resize: none;
  outline: none;
  height: 57px;
  font-family: ${fontsFamily.roboto};
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

  &.disabled {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #ffffff;
    cursor: not-allowed;
    & > * {
      cursor: not-allowed;
    }
  }
`;

export const TextInput = styled.input`
  background: ${colorsPalette.white};
  border-radius: 7.03922px;
  padding: 18px 7px;
  resize: none;
  outline: none;
  font-family: ${fontsFamily.mulish};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  &.disabled {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #ffffff;
    cursor: not-allowed;
    & > * {
      cursor: not-allowed;
    }
  }
`;

export const ContainerWithIcon = styled.div`
  min-width: 167px;
  height: 37px;
  padding: 5px 15px;
  background: ${colorsPalette.white};
  border-radius: 7.03922px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  display: flex;
  align-items: center;
  gap: 0.2rem;
  &.disabled {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
      #ffffff;
    cursor: not-allowed;
    & > * {
      cursor: not-allowed;
    }
  }
`;

interface IIcon {
  $width: number;
  $height: number;
}

export const Icon = styled.img<IIcon>`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  cursor: pointer;
`;

export const ArrowDownIcon = styled.img`
  width: 16px;
  height: 9px;
  cursor: pointer;
`;

export const InputWithIcon = styled.input`
  background: none;
  outline: none;
  border: none;
  text-align: center;
  font-family: ${fontsFamily.roboto};
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7b809a;
  flex: 1;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const SelectStyle = styled.select`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;

  font-family: ${fontsFamily.roboto};
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7b809a;
  outline: none;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
  &.disabled {
    background: #d7d7d7;
  }
`;
