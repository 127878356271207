import styled from 'styled-components';

interface ImageProps {
  widthImage?: string;
}

export const StyleBrand = styled.img<ImageProps>`
  width: 100%;
  max-width: ${({ widthImage }) => (widthImage ? widthImage : '129.04px')};
`;
