import styled from 'styled-components';
import fontsFamily from 'theme/fonts';

export const SubElements = styled.div`
  padding-left: 1.1rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: 3px;
  column-gap: 10px;
  align-items: center;
`;

interface IExpandRows {
  init: number;
  end: number;
}

export const ExpandRows = styled.div<IExpandRows>`
  display: flex;
  gap: 0.5rem;
  grid-row: ${({ init, end }) => `${init} / ${end}`};
`;

export const Input = styled.input`
  font-family: ${fontsFamily.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5b5b5b;
  padding: 3.5px 1.5px;
`;

export const Select = styled.select`
  font-family: ${fontsFamily.inter};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5b5b5b;
  padding: 3.5px 1.5px;
`;
