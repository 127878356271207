import styled, { keyframes } from 'styled-components';

// Animation
export const openSideBar = keyframes`
  from {
    grid-template-columns: 1fr 0fr;
  }

  to {
    grid-template-columns: 2fr 0.7fr;
  }
`;

export const closeSideBar = keyframes`
  from {
    grid-template-columns: 2fr 0.7fr;
  }
  to {
    grid-template-columns: 1fr 0fr;
  }
`;

export const SessionContainer = styled.div`
  background: #040a20;
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  color: white;
`;

export const VideoMainContainer = styled.section`
  width: 100%;
  position: absolute;
  padding: 24px 78px;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const VideoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.527rem;
  width: 100%;
  max-width: 1200px;
`;

export const WrapperContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-areas: 'main';
  width: 100%;
  &.active_sidebar {
    animation: ${openSideBar} 1s forwards;
  }

  &.close_sidebar_true {
    animation: ${closeSideBar} 1s forwards;
  }
`;

export const ContainerStream = styled.div`
  display: flex;
  position: relative;
  grid-area: main;
`;
