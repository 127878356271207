import React from 'react';
import LogoFondo from 'assets/images/svgs/LogoFondo';
import LogoFront from 'assets/images/svgs/LogoFront';
import { Background, ComingSoonText, Container, FrontContainer } from './style';

const ComingSoon = () => {
  return (
    <Container>
      <Background>
        <LogoFondo />
      </Background>
      <FrontContainer>
        <LogoFront />
        <ComingSoonText variant="INTER_48_700">
          Coming Soon<span></span>
          <span></span>
          <span></span>
        </ComingSoonText>
      </FrontContainer>
    </Container>
  );
};

export default ComingSoon;
