import Text from 'components/atoms/Text';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
`;

export const Background = styled.div`
  position: absolute;
  z-index: 0;
  & > svg {
    width: calc(100vw - 80px);
    height: calc(100vh - 80px);
  }
`;

export const FrontContainer = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: max-content;
  max-width: 946.04px;
  & > svg {
    width: 100%;
    max-height: max-content;
  }
`;

const dotsAnimation = keyframes`
  50% {
    opacity: 0;
    transform: scale(0.7) translateY(10px);
  }
`;

export const ComingSoonText = styled(Text)`
  color: #5cb770;
  margin-top: -30px;

  & span {
    width: 10px;
    height: 10px;
    background: #5cb770;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    animation: ${dotsAnimation} 2s infinite ease-in-out;
  }

  & span:nth-child(2) {
    animation-delay: 0.4s;
  }
  & span:nth-child(3) {
    animation-delay: 0.8s;
  }
`;
