import styled from 'styled-components';

import theme from 'theme';

import Text from 'components/atoms/Text';
import colorsPalette from 'theme/colors';

const { mediaQueries, colors, fontsFamily } = theme;

export const HeaderStyle = styled.div`
  &.container__header {
    width: 100%;
    height: max-content;
    grid-area: head;
    display: grid;
    grid-template-columns: 239px 1fr max-content;
    grid-auto-rows: max-content;
    align-items: center;
    ${mediaQueries.mediumScreen} {
    }
  }
`;

export const NavLogo = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    max-width: 188px;
  }
`;

export const TitleContaniner = styled.div`
  padding-left: 2rem;
`;

export const TextStyle = styled(Text)``;

export const RightContaniner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.1rem 0 8.5rem;
  justify-content: space-between;
  gap: 25px;
  ${mediaQueries.xLargeScreen} {
    padding: 0 0.1rem 0 12rem;
  }
`;

export const OptionRight = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
  color: ${colorsPalette.white};
`;

export const AvatarContainer = styled.div`
  width: 30px;
  height: 30px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 75px;
  margin-right: 0.75rem;
  justify-content: space-between;
  background-color: ${colors.white};
`;

export const AvatarStyle = styled.img``;

export const DropdownStyle = styled.div`
  position: absolute;
  border-radius: 12px;
  background-color: ${colors.blueAquaOpacity(0.47)};
  box-shadow: 0px 4px 4px ${colors.blackOpacity(0.25)};
  border-top-right-radius: 0;
  backdrop-filter: blur(7.5px);
  top: 0;
  right: 0;
  clear: both;
  min-width: 220px;
  padding: 12.25px 13px;
  z-index: 100;
`;

export const UlDropdownStyle = styled.ul`
  padding: 0 calc(33px - 13px);
  margin-top: calc(41px - 16px);
`;

export const LiDropdownStyle = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.white};
  font-family: ${fontsFamily.inter};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  height: 51px;

  &:last-child {
    border: none;
  }
`;

export const AncleDropdownStyle = styled.a`
  color: ${colors.white};
  font-family: ${fontsFamily.inter};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

export const HiddenDropDown = styled.div`
  color: ${colors.blueArrow};
  cursor: pointer;
  & > svg {
    transform: rotate(-180deg);
  }
`;
