import styled from 'styled-components';

import theme from 'theme';

import Input from 'components/atoms/Input';

const { colors, mediaQueries } = theme;

export const Container = styled.form`
  height: 100%;
  width: 100%;
  padding: 52px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.white};
`;

export const Information = styled.div`
  margin: 43px 0;
  height: max-content;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 2.5rem);
  width: 100%;
  gap: 0.2rem;
  grid-auto-rows: 3rem;
  justify-content: center;
  margin-top: 70px;
  ${mediaQueries.xSmallScreen} {
    grid-template-columns: repeat(6, 3.125rem);
    gap: 0.688rem;
    grid-auto-rows: 3.003rem;
  }

  ${mediaQueries.smallScreen} {
    grid-template-columns: repeat(6, 4.223rem);
    gap: 1.063rem;
    grid-auto-rows: 4.158rem;
    margin-top: 17px;
  }
`;

export const InputStyled = styled(Input)`
  box-shadow: 0px 2px 4px -3px #5cb770;
  border-radius: 8px;
  text-align: center;
`;

export const VerifyContainer = styled.div`
  max-width: 100%;
  width: 335px;
  margin-top: 25px;
`;

export const InvalidCodeContainer = styled.div`
  text-align: left;
  margin-top: 15px;
`;
