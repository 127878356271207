import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

import theme from 'theme';

import Text from 'components/atoms/Text';

const { colors, mediaQueries } = theme;

interface ContainerProps {
  hiddeInMobile: boolean;
}

export const Container = styled.nav<ContainerProps>`
  width: 100%;
  height: 92px;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  background: white;
  padding: 0;
  display: ${({ hiddeInMobile }) => (hiddeInMobile ? 'none' : 'flex')};

  ${mediaQueries.mediumScreen} {
    display: flex;
  }
`;

export const ItemProvider = styled(NavLink)`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 0;
  color: ${colors.white};
  &.active {
    background: ${colors.white};
  }
`;

export const ItemOmitProvider = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 0;
  background: ${colors.darkBlue};
  &.active {
    background: ${colors.darkBlue};
  }
`;

export const ItemPatient = styled(NavLink)`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 0;
  background: ${colors.darkBlue};
  &.active {
    background: ${colors.darkBlue};
  }
`;

export const ItemOmitPatient = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 0;
  color: ${colors.white};
  &.active {
    background: ${colors.white};
  }
`;

interface BackgroundProps {
  isDark: boolean;
  isPatient?: boolean;
}

export const Background = styled.div<BackgroundProps>`
  background: ${({ isDark }) => (isDark ? colors.darkBlue : colors.white)};
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  ${({ isPatient }) => {
    if (isPatient) {
      return `
        border-bottom-left-radius: 40px;
      `;
    }

    return `border-bottom-right-radius: 40px;`;
  }}
  &.active-provider {
    border-radius: 0px;
    border-top-right-radius: 40px;

    &.is-forgot {
      border-top-right-radius: 0;
      background: ${colors.white};
      border-bottom-left-radius: 40px;
    }
  }
  &.active-patient {
    border-radius: 0px;
    border-top-left-radius: 40px;
    &.is-forgot {
      border-top-left-radius: 0;
      background: ${colors.darkBlue};
      border-bottom-right-radius: 40px;
    }
  }
`;

interface TextItemProps {
  isActive: boolean;
}

export const TextItem = styled(Text)<TextItemProps>`
  ${({ isActive }) => isActive && `border-bottom: 3px solid ${colors.green};`}
`;
