import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-right: 10%;
  column-gap: 14.29px;
`;

export const Image = styled.img`
  width: 40.48px;
  height: 40.48px;
  border-radius: 50%;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7.29px;
  width: 100%;
  text-align: left;
`;

export const BodyMessage = styled.div`
  background: #021246;
  border-radius: 8px;
  padding: 15.92px;
  width: max-content;
  max-width: 100%;
  width: fit-content;
`;

export const Label = styled.label`
  word-break: break-word;
`;
