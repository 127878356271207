import styled, { css } from 'styled-components';

import theme from 'theme';

import { cssTextVariant } from 'components/atoms/Title/style';

const { colors, fontsFamily } = theme;

export type VariantButton =
  | 'FULL_GREEN'
  | 'OUTLINE_WHITE'
  | 'YELLOW_FILLING'
  | 'RED_FILLING'
  | 'SESSION_BUTTON'
  | 'OUTLINE_BLUE'
  | 'FULL_BLUE';

export const cssButtonVariant = {
  OUTLINE_BLUE: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 25px;
    gap: 10px;
    border: 1px solid #366bb3;
    border-radius: 16px;
    font-family: ${fontsFamily.inter};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #366bb3;
    cursor: pointer;
  `,
  FULL_BLUE: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 25px;
    gap: 10px;
    background: #366bb3;
    border: 1px solid #366bb3;
    border-radius: 16px;
    font-family: ${fontsFamily.inter};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.white};
    cursor: pointer;
  `,
  FULL_GREEN: css`
    width: auto;
    cursor: pointer;
    background: ${colors.green};
    box-shadow: 0px 2px 6px ${colors.whiteOpacity(0.1)};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 10px;

    &: hover {
      background: ${colors.white};
      border: solid 1px ${colors.green};

      p {
        color: ${colors.green} !important;
      }
    }
  `,

  OUTLINE_WHITE: css`
    width: auto;
    cursor: pointer;
    color: ${colors.green};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 24px;
    gap: 10px;

    &: hover {
      border-bottom: solid 1px ${colors.green};
    }
  `,

  YELLOW_FILLING: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 1px rgba(0, 0, 0, 0.08);
    background: ${colors.buttonYellow};
    color: ${colors.white};
    font-size: 14px;
    cursor: pointer;
    font-family: ${fontsFamily.inter};
  `,

  RED_FILLING: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 1px rgba(0, 0, 0, 0.08);
    background: ${colors.buttonRed};
    color: ${colors.white};
    font-size: 14px;
    cursor: pointer;
    font-family: ${fontsFamily.inter};
  `,

  SESSION_BUTTON: css`
    background: rgba(19, 21, 23, 0.63);
    border-radius: 33.6px;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
  `,
};

export type IBorderType = 'default' | 'NO_BORDER';

interface IButtonProps {
  $variant: VariantButton;
  $isBorder: IBorderType;
}

export const ButtonStyle = styled.span<IButtonProps>`
  ${cssTextVariant.HELVETICA_20_700}
  ${({ $variant }) => cssButtonVariant[$variant]}
  ${({ $isBorder }) => $isBorder === 'default' && `border-radius: 44px;`}
`;
