const blackOpacity = (percent: number) => `rgba(0, 0, 0, ${percent})`;

const whiteOpacity = (percent: number) => `rgba(255, 255, 255, ${percent})`;

const blueAquaOpacity = (percent: number) => `rgba(89, 174, 253, ${percent})`;

export const appTheme = {
  '1': {
    primary: '#0455BF',
    secondary: '#035AA6',
    third: '',
    fourth: '#0A8CBF',
    fifth: '#89C2D9',
    backdrop_container: 'rgba(255, 255, 255, 0.27)',
    callContainer: '#D7E1FE',
    blueCallsTxt: '#346AFF',
    gradient_patient_card:
      'linear-gradient( 90deg, #597eca 1.15%, #6185cb 39.76%, #699acc 78.09%)',
  },
  '2': {
    primary: '#306EBF',
    secondary: '#023E73',
    third: '#03658C',
    fourth: '#3DADD9',
    fifth: '#024959',
    backdrop_container: 'rgba(255, 255, 255, 0.27)',
    callContainer: '#D7E1FE',
    blueCallsTxt: '#346AFF',
    gradient_patient_card:
      'linear-gradient( 90deg, #597eca 1.15%, #6185cb 39.76%, #699acc 78.09%)',
  },
  '3': {
    primary: '#022873',
    secondary: '#056CF2',
    third: '#4395EB',
    fourth: '#05C7F2',
    fifth: '#05F2F2',
    backdrop_container: 'rgba(255, 255, 255, 0.27)',
    callContainer: '#D7E1FE',
    blueCallsTxt: '#346AFF',
    gradient_patient_card:
      'linear-gradient( 90deg, #597eca 1.15%, #6185cb 39.76%, #699acc 78.09%)',
  },
  '4': {
    primary: '#16227F',
    secondary: '#7988FF',
    third: '#2D43FF',
    fourth: '#3D447F',
    fifth: '#2436CC',
    backdrop_container: 'rgba(255, 255, 255, 0.27)',
    callContainer: '#D7E1FE',
    blueCallsTxt: '#346AFF',
    gradient_patient_card:
      'linear-gradient( 90deg, #597eca 1.15%, #6185cb 39.76%, #699acc 78.09%)',
  },
};

const colorsPalette = {
  black: '#000',
  blackOpacity,
  buttonRed: '#D03333',
  buttonYellow: '#E2B103',
  darkBlue: '#091930',
  darkRed: '#A60000',
  green: '#5CB770',
  red: '#FF0000',
  shadow: `0px 2px 4px ${blackOpacity(0.15)}`,
  white: '#FFF',
  whiteInputs: '#EFEFEF',
  whiteOpacity,
  appTheme,
  gray: '#7B809A',
  grayPlane: '#7E7E7E',
  blueArrow: '#4071d7',
  blueAquaOpacity,
};

export type TypesColors =
  | 'black'
  | 'white'
  | 'darkBlue'
  | 'whiteInputs'
  | 'red'
  | 'shadow'
  | 'green'
  | 'darkRed'
  | 'gray'
  | 'blueArrow';

export type TypesPallete = '1' | '2' | '3' | '4';

export default colorsPalette;
